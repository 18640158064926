import { MapTo } from '@adobe/aem-react-editable-components'
import withAsyncImport from 'utils/withAsyncImport'

const RelatedArticles = withAsyncImport(() =>
  import('./RelatedArticles')
)

const RelatedArticlesEditableConfig = {
  emptyLabel: 'Related Articles',

  isEmpty: function (props) {
    return !props || !props.configured
  }
}

export default MapTo('aramark/components/content/related-articles')(RelatedArticles, RelatedArticlesEditableConfig)
