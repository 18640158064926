import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './Textarea.module.scss'
import * as Framework from 'components/framework'

/**
 * The Textarea Component is used as...
 */
const Textarea = React.forwardRef(({
  addtClassName,
  analytics,
  disabled,
  error,
  id,
  isSubmitted,
  isValid,
  label,
  name,
  onChange,
  required,
  theme,
  type,
  ...props
}, ref) => {
  const [isDirty, setIsDirty] = useState(false)

  const cmpTheme = {
    label: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--large'
    },
    error: {
      color: 'text-color--red',
      font: 'font--gotham-book',
      size: 'text-size--large'
    }
  }

  return (
    // The component is wrapped in a container to group it with error and label components
    <label
      className={
        styles[theme] +
        (addtClassName ? ' ' + addtClassName : '')
      }
      id={`${id}-wrapper`}
    >
      {error && (
        <Framework.Text
          className={styles['error']}
          content={error}
          // If the input has a unique id, generate one for the error message as well
          id={`${id}-error`}
          inline
          role='alert'
          theme={cmpTheme.error}
        />
      )}
      <div
        className={
          styles.wrapper +
          (isSubmitted && isValid ? ' ' + styles['wrapper-success'] : '') +
          (error ? ' ' + styles['wrapper-error'] : '') +
          (isDirty ? ' ' + styles.dirty : '')
        }
        id={`${id}-container`}
      >
        <textarea
          // If an error is present and the input has a unique id, the aria-describedby attribute is added
          aria-describedby={error && id ? `${id}-error` : null}
          aria-disabled={disabled}
          aria-invalid={Boolean(error)}
          aria-placeholder={label}
          className={styles.input}
          disabled={disabled}
          id={`${id}-input`}
          name={name}
          onChange={(event) => {
            setIsDirty(event?.target?.value !== '')
            onChange(event)
          }}
          placeholder={label}
          ref={ref}
          required={required}
          // Additional props are added directly to the input element
          {...props}
        />
        {label && (
          <Framework.Text
            className={
              styles.label +
              (isSubmitted && isValid ? ' ' + styles['label-success'] : '') +
              (error ? ' ' + styles['label-error'] : '')
            }
            content={required ? '*' + label : label}
            id={`${id}-label`}
            inline
            theme={cmpTheme.label}
          />
        )}
      </div>
    </label>
  )
})

Textarea.propTypes = {
  /**
   * Additional Class Name - passed from the parent Component
   * -- allows box model adjustments (margin, padding, position styles etc)
   * -- should not be used for typography or color adjustments (font family, font weight, text color etc)
   */
   addtClassName: PropTypes.string,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    action: PropTypes.string,
    component: PropTypes.string,
    context: PropTypes.string,
    label: PropTypes.string
  }),
  /**
   * Disabled - disables the input
   */
  disabled: PropTypes.bool,
  /**
   * Error - error message to display
   */
  error: PropTypes.string,
  /**
   * ID - unique identifier for the input
   */
  id: PropTypes.string.isRequired,
  /**
   * Check if the field has already been submitted for validation
   */
  isSubmitted: PropTypes.bool,
  /**
   * Check if the field is valid
   */
  isValid: PropTypes.bool,
  /**
   * Textarea label
   */
  label: PropTypes.string,
  /**
   * Optional change handler
   */
  onChange: PropTypes.func,
  /**
   * Design theme
   */
  theme: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  /**
   * Name of the input
   */
  name: PropTypes.string,
  /**
   * Required - adds a required attribute to the input
   */
  required: PropTypes.bool
}

Textarea.defaultProps = {
  analytics: {
    action: '<Purpose of the interaction>',
    component: '<Parent component container identifier>',
    context: '<Additional information to add context to avoid ambiguity>',
    label: '<Text displayed within the button element>'
  },
  theme: 'primary'
}

Textarea.name = 'FormElement.textarea'
export default Textarea
