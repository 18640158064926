import { useCallback, useLayoutEffect, useState } from 'react'
import { useEventListener } from 'hooks/useEventListener'

/**
 * The useWindowSize hook returns the current window's height and width.
 */
export const useWindowSize = () => {
  const isClient = typeof window === 'object'
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })

  const handleSize = useCallback(() => {
    setWindowSize({
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    })

  }, [isClient])

  useEventListener('resize', handleSize)

  useLayoutEffect(() => {
    handleSize()
  }, [handleSize])

  return windowSize
}
