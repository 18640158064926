import { MapTo } from '@adobe/aem-react-editable-components'
import withAsyncImport from 'utils/withAsyncImport'

const BannerArticle = withAsyncImport(() =>
  import('./BannerArticle')
)

const BannerArticleEditableConfig = {
  emptyLabel: 'Banner - Article',

  isEmpty: function (props) {
    return !props || !props.configured
  }
}

export default MapTo('aramark/components/content/banner-article')(BannerArticle, BannerArticleEditableConfig)
