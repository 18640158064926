/* eslint-disable camelcase */
const isBrowser = (typeof window !== 'undefined')
//window.isDebuggingAnalytics=true // Uncomment to see all analytics tracking requests in the console
window.digitalData = window.digitalData || {}
window._satellite = window._satellite || { track: () => { if (window.isDebuggingAnalytics) console.log('_satellite not available for tracking') }}

/**
 * Page Tracking
 *
 * Event fires when a new page is loaded into the viewport
 *
 */
export const trackPageView = () => {
  if (isBrowser) {
    const lang= document.documentElement.lang === '' ? getLanguageFromPagePath() : document.documentElement.lang;
    const isHomePage = window.location?.pathname === '/home'
    const path = window.location?.pathname?.replace(/.html/g, '').split('/')
    const currentPage = path?.pop()
    const sectionObj = {
      description: (isHomePage) ? 'main' : currentPage,
      section1: (isHomePage) ? 'home' : path[1] || currentPage,
      section2: path[2] || undefined,
      section3: path[3] || undefined,
      section4: path[4] || undefined,
      section5: path[5] || undefined,
      section6: path[6] || undefined
    }
    var countryCode = '';
    if(lang.indexOf('-')!=-1) {
      countryCode = lang.split('-')[1]; 
    }else if(lang.indexOf('_')!=-1){
      countryCode = lang.split('_')[1];
    }
    else {
      countryCode = "US";
    }


    function getLanguageFromPagePath() {
      // Split the errorPagePath by "/"
      let errorPagePath = "";
      let lang="";
     if ( document.querySelector("meta[property='cq:errorpages']")) {
       errorPagePath = document.querySelector("meta[property='cq:errorpages']").getAttribute("content");
       const pathSegments = errorPagePath.split("/");
       // Language will be the fourth element in the pathSegments array
       lang = pathSegments[4];
     }
      return lang;
    }
    // Remove any props that are undefined
    Object.keys(sectionObj).forEach(key => sectionObj[key] === undefined && delete sectionObj[key])
    window.digitalData = {
      pageInfo: {
        site: 'aramark-' + countryCode.toLowerCase(), // TODO: this should be passed from the page properties
        ...sectionObj
      },
      event: {
        type: 'pageview'
      }
    }
    if (window.isDebuggingAnalytics) {
      console.log('ui.frontend/src/utils/analytics.js logging window.digitalData before page view _satellite.track("eventPageview") call')
      console.log(window.digitalData)
    }
    window._satellite?.track('eventPageview')
  }
}

/**
 * Interaction Tracking
 *
 * Event fired when a non-navigation element interaction occurs
 *
 * @param {string} action Optional - purpose of the interaction e.g. next, previous, open, close
 * @param {string} component Optional - parent component container identifier
 * @param {string} context Required - additional information to add context to avoid ambiguity
 * @param {string} label Optional - if applicable the text displayed within the button element
 *
 */
export const trackInteraction = ({
  action,
  component,
  context,
  label
}) => {
  if (isBrowser) {
    window.digitalData.event = {
      interaction__name: action || 'cta',
      interaction__text: label || '',
      interaction__destination: '', // left empty as not relevant for interaction tracking but part of specification
      interaction__component: component || '',
      interaction__link__context: context || ''
    }
    if (window.isDebuggingAnalytics) {
      console.log('ui.frontend/src/utils/analytics.js logging window.digitalData before interactive element _satellite.track("eventInteraction") call')
      console.log(window.digitalData)
    }
    window._satellite?.track('eventInteraction')
  }
}

/**
 * Navigation Tracking
 *
 * Event fired when a navigation element interaction occurs
 *
 * TODO: Support additional types: files, anchors
 *
 * @param {string} component Optional - parent component container identifier
 * @param {string} context Required - additional information to add context to avoid ambiguity
 * @param {string} destination Optional - target url
 * @param {string} label Optional - if applicable the label of the element
 * @param {string} type Optional - whether the destination is internal, external or affiliated
 *
 */
export const trackNavigation = ({
  component,
  context,
  destination,
  label,
  type
}) => {
  if (isBrowser) {
    window.digitalData.event = {
      interaction__name: type || 'cta',
      interaction__text: label || '',
      interaction__destination: destination || '',
      interaction__component: component || '',
      interaction__link__context: context || ''
    }
    if (window.isDebuggingAnalytics) {
      console.log('ui.frontend/src/utils/analytics.js logging window.digitalData before navigation element _satellite.track("eventInteraction") call')
      console.log(window.digitalData)
    }
    window._satellite?.track('eventInteraction')
  }
}

/*
 * Process Tracking
 *
 * Event fired when a process event occurs
 *
 * @param {string} action Optional - purpose of the interaction e.g. next, previous, open, close
 * @param {string} type Required - Type of process
 * @param {string} context Required - additional information to add context to avoid ambiguity
 * @param {string} value Optional - Error message
 *
 */
export const trackProcessEvent = ({
  action,
  type,
  context,
  value
}) => {
  if (isBrowser) {
    window.digitalData.event = {
      type: type,
      processName: context,
      processStep: action,
      value: value
    }
    if (window.isDebuggingAnalytics) {
      console.log('ui.frontend/src/utils/analytics.js logging window.digitalData before process event _satellite.track("eventProcess") call')
      console.log(window.digitalData)
    }
    window._satellite?.track('eventInteraction')
  }
}

/**
 * Page Scroll Depth Tracking
 *
 * Event fired when page scrolling boundries are crossed
 *
 * @param {string} percent Required - percentage of page scrolled  10%, 20%, 30%, etc
 *
 */
export const trackScrollDepth = (
  percent
) => {
  if (isBrowser) {
    window.digitalData.event = {
      scrollDepth: percent
    }
    if (window.isDebuggingAnalytics) {
      console.log('ui.frontend/src/utils/analytics.js logging window.digitalData before page scroll _satellite.track("eventScrollDepth") call')
      console.log('scrollDepth value passed through: ' + percent)
      console.log(window.digitalData)
    }
    window._satellite?.track('eventScrollDepth')
  }
}

/**
 * Add Nav tracking
 * 
 * Add navigation tracking to links in components that support rich text
 */
export const addNavTracking = (
  cmpAnalytics,
  id
) => {
  const links = document?.getElementById(id)?.getElementsByTagName('a') || []

  for (let link of links) {
    link.onclick = () => {
      trackNavigation({
        component: cmpAnalytics?.component,
        context: cmpAnalytics.context,
        destination: link?.href,
        label: link?.innerText
      })
    }
  }
}

/*
TODO: Performance Events
- First paint
- DOM Loaded
*/
