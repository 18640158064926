import React from 'react'
import PropTypes from 'prop-types'
import styles from './NavigationMainSearch.module.scss'
import * as Framework from 'components/framework'
import SearchFormConfig from './SearchFormConfig'
import { useHistory } from 'react-router-dom'

/**
 * The Navigation - Main - Menu Component is used to display menu content
 */
const NavigationMainSearch = ({
  addtOnSubmit,
  analytics,
  i18n
}) => {

  const history = useHistory()
  const handleRedirect = (searchTerm) => {
    if ((searchTerm !== '') && (searchTerm !== undefined)) {
      addtOnSubmit()
      history.push(`${i18n?.searchPage}?q=${searchTerm}`)
    }
  }

  return (
    <div className={styles.search}>
      <div className={styles.formcontainer}>
        <Framework.Form
          analytics={{
            component: analytics?.component,
            context: 'Search'
          }}
          id='nav-search-results-form'
          onSubmit={(data) => handleRedirect(data?.search)}
          {...SearchFormConfig.form}
        />
      </div>
    </div>
  )
}

NavigationMainSearch.propTypes = {
  /**
   * Additional On Submit function
   */
   addtOnSubmit: PropTypes.func,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    component: PropTypes.string,
    context: PropTypes.string
  }),
  /**
   * i18n - Translation copy
   */
  i18n: PropTypes.object
}

export default NavigationMainSearch
