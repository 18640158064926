//import withAsyncImport from 'utils/withAsyncImport'
import { MapTo } from '@adobe/aem-react-editable-components'
import withAsyncImport from 'utils/withAsyncImport'

const RichText = withAsyncImport(() =>
  import('./RichText')
)

//const RichText = withAsyncImport(() => import('./RichText'))

const RichTextEditableConfig = {
  emptyLabel: 'Rich Text',

  isEmpty: function (props) {
    return !props || !props.configured
  }
}

export default MapTo('aramark/components/content/richtext')(RichText, RichTextEditableConfig)