import sanitizeHtml from 'sanitize-html'
import sanitizeWhiteList from 'components/sanitize-html.whitelist'

import React from 'react'
import PropTypes from 'prop-types'
import styles from './Text.module.scss'
import Typography from 'components/scaffolding/Typography'

/**
 * The Text Component is used as...
 */
const Text = ({
  addtClassName,
  content,
  inline,
  theme,
  ...props
}) => {
  // Get semantic tag from inline value
  const Tag = inline ? 'span' : 'div'

  return (
    <Tag
      className={addtClassName ? styles.text + ' ' + addtClassName : styles.text}
      {...props}
    >
      <Typography
        color={theme?.color}
        font={theme?.font}
        linesToDisplay={theme?.linesToDisplay}
        size={theme?.size}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(content, sanitizeWhiteList)
        }}
      />
    </Tag>
  )
}

Text.propTypes = {
  /**
   * Additional Class Name - passed from the parent Component
   * -- allows box model adjustments (margin, padding, position styles etc)
   * -- should not be used for typography or color adjustments (font family, font weight, text color etc)
   */
  addtClassName: PropTypes.string,
  /**
   * Content
   */
  content: PropTypes.string,
  /**
   * Inline - if true, the text will be rendered inline
   */
  inline: PropTypes.bool,
  /**
   * Theme
   */
  theme: PropTypes.shape({
    color: PropTypes.string,
    font: PropTypes.string,
    linesToDisplay: PropTypes.number,
    size: PropTypes.string
  })
}

Text.defaultProps = {
  content: 'Missing content text',
  inline: false,
  theme: {
    color: 'text-color--black',
    font: 'font--gotham-book',
    size: 'text-size--regular'
  }
}

export default Text
