import FontLoading from './FontLoading'
import PageScrollingResetOnRouteChange from './PageScrollingResetOnRouteChange'
import PageViewTrackingOnRouteChange from './PageViewTrackingOnRouteChange'
import ScrollToTopOnRouteChange from './ScrollToTopOnRouteChange'

const PageHelper = () => {

  return (
    <>
      <FontLoading/>
      <ScrollToTopOnRouteChange />
      <PageViewTrackingOnRouteChange />
      <PageScrollingResetOnRouteChange />
    </>
  )

}

export default PageHelper
