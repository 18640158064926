import React from 'react'
import PropTypes from 'prop-types'
import * as Framework from 'components/framework'

const Element = React.forwardRef(({type, params, formServicesAreas, ...props}, ref) => {
  const d = new Date();
  const currentDate = d.toISOString().slice(0,10);

  switch (type) {
    case 'checkbox':
      return (
        <Framework.FormCheckbox
          ref={ref}
          {...params}
          {...props}
        />
      )
    case 'checkbox-group':
      return (
        <Framework.FormCheckboxGroup
          ref={ref}
          formServicesAreas={formServicesAreas}
          {...params}          
          {...props}

        />
      )
    case 'comments':
      return (
        <Framework.FormTextarea
          ref={ref}
          rows='6'
          maxLength='1020'
          {...params}
          {...props}
        />
      )
    case 'email':
      return (
        <Framework.FormTextfield
          type='email'
          ref={ref}
          {...params}
          {...props}
        />
      )
    case 'list':
      return (
        <Framework.FormSelect
          ref={ref}
          {...params}
          {...props}
        />
      )
    case 'firstName':
      return (
        <Framework.FormTextfield
          type='text'
          ref={ref}
          {...params}
          {...props}
        />
      )
    case 'lastName':
      return (
        <Framework.FormTextfield
          type='text'
          ref={ref}
          {...params}
          {...props}
        />
      )
    case 'password':
      return (
        <Framework.FormTextfield
          type='password'
          ref={ref}
          {...params}
          {...props}
        />
      )
    case 'phone':
      return (
        <Framework.FormTextfield
          type='tel'
          maxLength='10'
          ref={ref}
          {...params}
          {...props}
        />
      )
    case 'radio':
      return (
        <Framework.FormRadio
          ref={ref}
          {...params}
          {...props}
        />
      )
    case 'radio-group':
      return (
        <Framework.FormRadioGroup
          ref={ref}
          {...params}
          {...props}
        />
      )
    case 'reset':
      return (
        <Framework.FormButton
          type='reset'
          {...params}
          {...props}
        />
      )
    case 'submit':
      return (
        <Framework.FormButton
          type='submit'
          {...params}
          {...props}
        />
      )
    case 'text':
      return (
        <Framework.FormTextfield
          type='text'
          ref={ref}
          {...params}
          {...props}
        />
      )
    case 'textarea':
      return (
        <Framework.FormTextarea
          ref={ref}
          {...params}
          {...props}
        />
      )
    case 'url':
      return (
        <Framework.FormTextfield
          type='url'
          ref={ref}
          {...params}
          {...props}
        />
      )
    case 'zip':
      return (
        <Framework.FormTextfield
          type='text'
          maxLength='5'
          ref={ref}
          {...params}
          {...props}
        />
      )
    case 'company':
      return (
        <Framework.FormTextfield
          type='text'
          ref={ref}
          {...params}
          {...props}
        />
      )
    case 'industry':
      return (
        <Framework.FormSelect
          ref={ref}
          {...params}
          {...props}
        />
      )
    case 'serviceInterests':
      return (
        <Framework.FormCheckboxGroup
          ref={ref}
          {...params}          
          {...props}
        />
      )

    case 'serviceFamily':
      return (
        <Framework.FormCheckboxGroup
          ref={ref}
          {...params}
          {...props}
        />
      )
    case 'serviceCategory':
      return (
        <Framework.FormCheckboxGroup
          ref={ref}
          {...params}
          {...props}
        />
      )
      case 'state':
      return (
        <Framework.FormTextfield
          type='text'
          ref={ref}
          {...params}
          {...props}
        />
      )
      case 'empNum':
        return (
          <Framework.TextfieldNumber
          type='tel'
            ref={ref}
            maxLength='7'
            {...params}
            {...props}
          />
        )
    case 'telPhone':
      return (
          <Framework.TextfieldNumber
              type='tel'
              ref={ref}
              // maxLength='11'
              {...params}
              {...props}
          />
      )
      case 'datePicket':
        return (
          <Framework.FormTextfield
          type='date'
          ref={ref}
          {...params}
          {...props}
          max={currentDate}
        />
        )
        case 'empId':
          return (
            <Framework.TextfieldNumber
            type='text'
              ref={ref}
              maxLength='9'
              {...params}
              {...props}
            />
          )
          case 'usSSN':
            return (
              <Framework.TextfieldNumber
                type='text'
                ref={ref}
                maxLength='4'
                {...params}
                {...props}
              />
            )
            case 'canadaSSN':
              return (
                <Framework.TextfieldNumber
                type='text'
                  ref={ref}
                  maxLength='3'
                  {...params}
                  {...props}
                />
              )
              case 'TextView':
                return (
                  <Framework.TextView
                  type='text'
                    ref={ref}
                    {...params}
                    {...props}
                  />
                )
    default:
      return null
  }
})

Element.propTypes = {
  type: PropTypes.oneOf([
    'checkbox',
    'checkbox-group',
    'comments',
    'email',
    'list',
    'firstName',
    'lastName',
    'password',
    'phone',
    'radio',
    'radio-group',
    'reset',
    'submit',
    'text',
    'textarea',
    'url',
    'zip',
    'company',
    'industry',
    'serviceInterests',
    'serviceFamily',
    'serviceCategory',
    'state',
    'empNum',
    'telPhone',
    'empId',
    'datePicket',
    'usSSN',
    'canadaSSN',
    'TextView'


  ]).isRequired,
  params: PropTypes.object
}

export default Element
