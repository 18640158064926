import React from 'react'
import PropTypes from 'prop-types'
import styles from './Typography.module.scss'

/**
 * The Typography Component is used to understand the various combinations of font 
 * family, size and color that can be applied to textual elements.
 */
const Typography = ({
  color,
  font,
  linesToDisplay,
  size,
  ...props
}) => {

  const generateLineClamp = (lines) => {
    if (lines === undefined) return ''
    return styles['line-clamp--' + lines]
  }

  return (
    <span
      className={`${color} ${font} ${size} ` + generateLineClamp(linesToDisplay)}
      {...props}
    >
      {props?.children}
    </span>
  )
}

Typography.propTypes = {
  /**
   * Color
   *  - To use the parent element color pass 'text-color--inherit'
   */
  color: PropTypes.oneOf([
    'text-color--inherit',
    'text-color--black',
    'text-color--dark-blue',
    'text-color--dark-gray',
    'text-color--dark-yellow',
    'text-color--gold',
    'text-color--light-gray',
    'text-color--medium-blue',
    'text-color--midnight-blue',
    'text-color--modified-light-blue',
    'text-color--modified-medium-blue',
    'text-color--near-black',
    'text-color--none',
    'text-color--red',
    'text-color--teal',
    'text-color--white'
  ]),
  /**
   * Font
   */
  font: PropTypes.oneOf([
    'font--gotham-book',
    'font--gotham-medium',
    'font--gotham-bold',
    'font--reckless-neue',
    'font--reckless-neue-italic'
  ]),
  /**
   * Lines to display
   */
  linesToDisplay: PropTypes.number,
  /**
   * Size
   */
  size: PropTypes.oneOf([
    'headline-text-size--xx-large',
    'headline-text-size--x-large',
    'headline-text-size--large',
    'headline-text-size--medium',
    'headline-text-size--small',
    'headline-text-size--x-small',
    'text-size--card-title',
    'text-size--eyebrow',
    'text-size--large',
    'text-size--regular',
    'text-size--small-alertBanner',
    'text-size--small',
    'text-size--x-small',
    'text-size--cta',
    'text-size--caps-accent',
    'text-size--sub-navigation'
  ])
}

Typography.defaultProps = {
  color: 'text-color--black',
  font: 'font--gotham-book',
  size: 'text-size--regular'
}

export default Typography
