const AramarkIconBlack = <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 299.62 211.48' width='52' height='35' fill='#000000'>
  <path d='M205.44 49.38c7.71 0 15.09-3.7 19.75-9.89 3.23-4.31 4.94-9.42 4.94-14.8s-1.71-10.5-4.94-14.81C220.53 3.69 213.15 0 205.44 0c-13.62 0-24.7 11.07-24.7 24.69.01 13.61 11.09 24.69 24.7 24.69zm94 1.48c-.27-.33-.82-.36-1.46-.16l-.35.13c-18.53 6.8-59.36 14.2-114.33 4.43-65.46-11.63-88.96-5.78-100.51-1.56-.1.04-.2.07-.29.11-.7.29-1.13.74-1.1 1.21.03.48.54.87 1.28 1.07.04 0 .07.01.1.02 82.65 18.56 31.49 87.66-81.34 152.84-.2.1-.4.2-.56.32-.64.44-.98.99-.85 1.45.16.53.91.8 1.88.75.09-.01.18-.01.25-.02 19.68-1.77 48.67-12.3 83.48-27.79.01 0 .02 0 .04-.02l12.42-5.63c17.59-7.7 37.56-14.91 50.24-10.92 16.71 5.26 19.93 17.71 21.93 41.31.01.12.03.24.04.37.08.61.32 1.06.72 1.18.43.13.93-.17 1.37-.72.05-.07.1-.14.16-.21 8.12-11.17 38.25-54.82 39.96-89.28 40.48-23.47 74.26-47.56 86.47-66.81a3.26 3.26 0 0 0 .39-.63c.29-.57.34-1.11.06-1.44zM205.28 189.2c0-1.22-.9-1.94-2.3-1.94h-2.61v5.87h1.3v-1.89h1.02l1.25 1.89h1.51l-1.43-2.1c.75-.27 1.27-.87 1.26-1.83zm-2.3.93h-1.3v-1.72h1.26c.64 0 1.04.29 1.05.87 0 .5-.35.85-1.01.85zm-.35-5.67c-3.23 0-5.85 2.62-5.85 5.85s2.62 5.85 5.85 5.85 5.85-2.62 5.85-5.85-2.62-5.85-5.85-5.85zm0 10.5a4.66 4.66 0 0 1-4.65-4.65 4.66 4.66 0 0 1 4.65-4.65 4.66 4.66 0 0 1 4.65 4.65 4.66 4.66 0 0 1-4.65 4.65z'/>
</svg>

const AramarkIconRed = <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 299.62 211.48' width='52' height='35' fill='#eb002a'>
  <path d='M205.44 49.38c7.71 0 15.09-3.7 19.75-9.89 3.23-4.31 4.94-9.42 4.94-14.8s-1.71-10.5-4.94-14.81C220.53 3.69 213.15 0 205.44 0c-13.62 0-24.7 11.07-24.7 24.69.01 13.61 11.09 24.69 24.7 24.69zm94 1.48c-.27-.33-.82-.36-1.46-.16l-.35.13c-18.53 6.8-59.36 14.2-114.33 4.43-65.46-11.63-88.96-5.78-100.51-1.56-.1.04-.2.07-.29.11-.7.29-1.13.74-1.1 1.21.03.48.54.87 1.28 1.07.04 0 .07.01.1.02 82.65 18.56 31.49 87.66-81.34 152.84-.2.1-.4.2-.56.32-.64.44-.98.99-.85 1.45.16.53.91.8 1.88.75.09-.01.18-.01.25-.02 19.68-1.77 48.67-12.3 83.48-27.79.01 0 .02 0 .04-.02l12.42-5.63c17.59-7.7 37.56-14.91 50.24-10.92 16.71 5.26 19.93 17.71 21.93 41.31.01.12.03.24.04.37.08.61.32 1.06.72 1.18.43.13.93-.17 1.37-.72.05-.07.1-.14.16-.21 8.12-11.17 38.25-54.82 39.96-89.28 40.48-23.47 74.26-47.56 86.47-66.81a3.26 3.26 0 0 0 .39-.63c.29-.57.34-1.11.06-1.44zM205.28 189.2c0-1.22-.9-1.94-2.3-1.94h-2.61v5.87h1.3v-1.89h1.02l1.25 1.89h1.51l-1.43-2.1c.75-.27 1.27-.87 1.26-1.83zm-2.3.93h-1.3v-1.72h1.26c.64 0 1.04.29 1.05.87 0 .5-.35.85-1.01.85zm-.35-5.67c-3.23 0-5.85 2.62-5.85 5.85s2.62 5.85 5.85 5.85 5.85-2.62 5.85-5.85-2.62-5.85-5.85-5.85zm0 10.5a4.66 4.66 0 0 1-4.65-4.65 4.66 4.66 0 0 1 4.65-4.65 4.66 4.66 0 0 1 4.65 4.65 4.66 4.66 0 0 1-4.65 4.65z'/>
</svg>

const AramarkIconWhite = <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 299.62 211.48' width='52' height='35' fill='#ffffff'>
  <path d='M205.44 49.38c7.71 0 15.09-3.7 19.75-9.89 3.23-4.31 4.94-9.42 4.94-14.8s-1.71-10.5-4.94-14.81C220.53 3.69 213.15 0 205.44 0c-13.62 0-24.7 11.07-24.7 24.69.01 13.61 11.09 24.69 24.7 24.69zm94 1.48c-.27-.33-.82-.36-1.46-.16l-.35.13c-18.53 6.8-59.36 14.2-114.33 4.43-65.46-11.63-88.96-5.78-100.51-1.56-.1.04-.2.07-.29.11-.7.29-1.13.74-1.1 1.21.03.48.54.87 1.28 1.07.04 0 .07.01.1.02 82.65 18.56 31.49 87.66-81.34 152.84-.2.1-.4.2-.56.32-.64.44-.98.99-.85 1.45.16.53.91.8 1.88.75.09-.01.18-.01.25-.02 19.68-1.77 48.67-12.3 83.48-27.79.01 0 .02 0 .04-.02l12.42-5.63c17.59-7.7 37.56-14.91 50.24-10.92 16.71 5.26 19.93 17.71 21.93 41.31.01.12.03.24.04.37.08.61.32 1.06.72 1.18.43.13.93-.17 1.37-.72.05-.07.1-.14.16-.21 8.12-11.17 38.25-54.82 39.96-89.28 40.48-23.47 74.26-47.56 86.47-66.81a3.26 3.26 0 0 0 .39-.63c.29-.57.34-1.11.06-1.44zM205.28 189.2c0-1.22-.9-1.94-2.3-1.94h-2.61v5.87h1.3v-1.89h1.02l1.25 1.89h1.51l-1.43-2.1c.75-.27 1.27-.87 1.26-1.83zm-2.3.93h-1.3v-1.72h1.26c.64 0 1.04.29 1.05.87 0 .5-.35.85-1.01.85zm-.35-5.67c-3.23 0-5.85 2.62-5.85 5.85s2.62 5.85 5.85 5.85 5.85-2.62 5.85-5.85-2.62-5.85-5.85-5.85zm0 10.5a4.66 4.66 0 0 1-4.65-4.65 4.66 4.66 0 0 1 4.65-4.65 4.66 4.66 0 0 1 4.65 4.65 4.66 4.66 0 0 1-4.65 4.65z'/>
</svg>

export {
  AramarkIconBlack,
  AramarkIconRed,
  AramarkIconWhite
}
