import { useCallback, useLayoutEffect, useRef } from 'react'

const isBrowser = typeof window !== `undefined`
const zeroPosition = { x: 0, y: 0 }

const getScrollPosition = () => {
  if (!isBrowser) {
    return zeroPosition
  }

  return { x: window.scrollX, y: window.scrollY }
}

export const useScrollPosition = (
  effect,
  wait
) => {
  const position = useRef(getScrollPosition())

  let throttleTimeout = useRef(null)

  const callBack = useCallback(() => {
    const current = getScrollPosition()
    effect({ previous: position.current, current })
    position.current = current
    throttleTimeout.current = null
  }, [effect])

  useLayoutEffect(() => {
    if (!isBrowser) {
      return undefined
    }

    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout.current === null) {
					throttleTimeout.current = window.setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)

      if (throttleTimeout.current) {
        clearTimeout(throttleTimeout.current)
      }
    }
  }, [callBack, wait])
}

useScrollPosition.defaultProps = {
  wait: 1000
}