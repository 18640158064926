export default <svg
xmlns="http://www.w3.org/2000/svg"
xmlnsXlink="http://www.w3.org/1999/xlink"
width="24px"
height="24px"
viewBox="0 0 24 24"
>
<g id="surface1">
  <path
    fill="#2F3A41"
    d="M 729.491699 387.920759 L 787.565755 387.920759 L 805.5 337.511161 L 823.434245 387.920759 L 881.508301 387.920759 L 834.523763 419.079241 L 852.458008 469.488839 L 805.5 438.330357 L 758.541992 469.488839 L 776.476237 419.079241 Z M 729.491699 387.920759 "
    transform="matrix(0.147239,0,0,0.148936,-106.601227,-49.595745)"
  />
</g>
</svg>