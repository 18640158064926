import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './Video.module.scss'
import * as Framework from 'components/framework'

/**
 * The Video Component is used to render a video player
 */
const Video = ({
  addtClassName,
  defaultAspectRatio,
  fullscreen,
  setIsPlayingVideo,
  isPlayingVideo,
  mobileAspectRatio,
  hasPosterImage,
  parentId,
  playerTitle,
  videoHost,
  videoId,
  ...props
}) => {

  const videoPlayer = useRef(null)

  const getSeparator = (string) => {
    const questionMark = string.indexOf('?')
    return questionMark > 0 ? '&' : '?'
  }

  useEffect(() => {
    if (isPlayingVideo) {
      const cPath = videoPlayer?.current?.src
      videoPlayer.current.src = (cPath?.indexOf('?')) ? cPath + `${getSeparator(cPath)}autoplay=1&playsinline=0` : cPath + `${getSeparator(cPath)}autoplay=1&playsinline=0`
    }
  }, [fullscreen, isPlayingVideo])

  const closePlayer = () => {
    setIsPlayingVideo(false)
  }

  return (
    <div
      className={`${styles.video} ${styles['aspect-ratio-' + defaultAspectRatio?.replace('/','-')]} ${addtClassName || ''}`}
      data-aspect-ratio={defaultAspectRatio}
      {...props}
    >
      <div
        className={isPlayingVideo && fullscreen ? styles.background : ''}
        style={(isPlayingVideo && fullscreen) ? { display: 'initial' } : {}}
      >
        {(() => {
          let videoPath = ''
          switch (videoHost) {
            case 'Ambient':
              videoPath = 'https://www.youtube.com/embed/' + videoId  + `${getSeparator(videoId)}enablejsapi=1&rel=0`
              return (
                <iframe
                  allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen='1'
                  frameBorder='0'
                  id={`${parentId}-ambient-video`}
                  modestbranding='1'
                  ref={videoPlayer}
                  rel='0'
                  src={videoPath}
                  title={playerTitle}
                  type='text/html'
                ></iframe>
              )
            case 'YouTube':
              videoPath = 'https://www.youtube.com/embed/' + videoId + `${getSeparator(videoId)}enablejsapi=1&rel=0`
              return (
                <>
                  {(isPlayingVideo || !hasPosterImage) && (<div className={fullscreen ? styles.fullscreen : styles.windowed}>
                    <Framework.Button
                      content={
                        <Framework.Icon icon='cross' />
                      }
                      onClick={() => closePlayer()}
                      theme={{
                        style: {},
                        type: 'wrapper'
                      }}
                    />
                    <div className={styles.wrapper}>
                      <iframe
                        allow={`accelerometer;${fullscreen || hasPosterImage ? ' autoplay;' : ''} encrypted-media; gyroscope; picture-in-picture`}
                        allowFullScreen='1'
                        frameBorder='0'
                        playsInline='0'
                        id={`${parentId}-youtube-video`}
                        modestbranding='1'
                        ref={videoPlayer}
                        rel='0'
                        src={videoPath}
                        title={playerTitle}
                        type='text/html'
                      ></iframe>
                    </div>
                  </div>)}
                </>
              )
            case 'Vimeo':
              videoPath = 'https://player.vimeo.com/video/' + videoId + `${getSeparator(videoId)}transparent=0&autoplay=${fullscreen || hasPosterImage ? '1' : '0'}`
              return (
                <>
                  {(isPlayingVideo || !hasPosterImage) && (<div className={fullscreen ? styles.fullscreen : styles.windowed}>
                    <Framework.Button
                      content={
                        <Framework.Icon icon='cross' />
                      }
                      onClick={() => closePlayer()}
                      theme={{
                        style: {},
                        type: 'wrapper'
                      }}
                    />
                    <div className={styles.wrapper}>
                      <iframe
                        allow={`accelerometer;${fullscreen || hasPosterImage? ' autoplay;' : ''} encrypted-media; gyroscope; picture-in-picture`}
                        allowFullScreen='1'
                        frameBorder='0'
                        playsInline='0'
                        id={`${parentId}-vimeo-video`}
                        ref={videoPlayer}
                        src={videoPath}
                        title={playerTitle}
                        type='text/html'
                      ></iframe>
                    </div>
                  </div>)}
                </>
              )
            case 'DAM':
              const accountId = '5377908866001' // TODO: Need to update with Aramark Account ID
              videoPath = 'https://players.brightcove.net/' + accountId + '/default_default/index.html?videoId=' + videoId
              return (
                <iframe
                  allowFullScreen='1'
                  frameBorder='0'
                  id={`${parentId}-dam-video`}
                  ref={videoPlayer}
                  rel='0'
                  src={videoPath}
                  title={playerTitle}
                  type='text/html'
                ></iframe>
              )
            default:
          }
        })()}
      </div>
    </div>
  )
}

Video.propTypes = {
  /**
   * Additional Class Name - passed from the parent Component
   * -- allows box model adjustments (margin, padding, position styles etc)
   * -- should not be used for typography or color adjustments (font family, font weight, text color etc)
   */
  addtClassName: PropTypes.string,
  /**
   * Default Aspect Ratio
   */
  defaultAspectRatio: PropTypes.oneOf([
    '1',
    '2/3',
    '3/2',
    '4/3',
    '9/16',
    '16/9',
    'banner',
    'banner-featured',
    'banner-full-width',
    'original'
  ]),
  /**
   * Play video fullscreen
   */
  fullscreen: PropTypes.bool,
  /**
   * Has poster image
   */
  hasPosterImage: PropTypes.bool,
  /**
   * Is Playing Video Setter
   */
  setIsPlayingVideo: PropTypes.func,
  /**
   * Is Playing Video Getter
   */
  isPlayingVideo: PropTypes.bool,
  /**
   * Mobile Aspect Ratio
   */
  mobileAspectRatio: PropTypes.oneOf([
    '1',
    '2/3',
    '3/2',
    '4/3',
    '9/16',
    '16/9',
    'banner',
    'banner-featured',
    'banner-full-width',
    'original'
  ]),
  /**
   * Parent component ID
   */
  parentId: PropTypes.string.isRequired,
  /**
   * Player Title
   */
  playerTitle: PropTypes.string,
  /**
   * Video Host
   */
  videoHost: PropTypes.oneOf([
    'Ambient',
    'YouTube',
    'DAM',
    'Vimeo'
  ]),
  /**
   * Video ID
   */
  videoId: PropTypes.string
}

Video.defaultProps = {
  defaultAspectRatio: '16/9',
  fullscreen: false,
  parentId: 'video-player',
  playerTitle: 'Video Player',
  videoHost: 'YouTube',
  videoId: ''
}

export default Video
