import React from 'react'
import PropTypes from 'prop-types'
import styles from './NavigationUtilityCard.module.scss'
import * as Framework from 'components/framework'

const NavigationUtilityCard = ({
  addtClassName,
  analytics,
  image,
  link,
  title,
  ...props
}) => {

  const cmpTheme = {
    fakeLink: {
      color: 'text-color--black',
      font: 'font--gotham-medium',
      linesToDisplay: 1,
      size: 'text-size--caps-accent'
    },
    link: {
      style: 'none',
      type: 'wrapper'
    },
    title: {
      color: 'text-color--black',
      font: 'font--gotham-book',
      linesToDisplay: 4,
      size: 'text-size--small'
    }
  }
  
  return (
    <div
      className={
        addtClassName ? styles.card + ' ' + addtClassName : styles.card
      }
      {...props}
    >
      <Framework.Anchor
        analytics={{
          component: analytics ? analytics?.component + '|Utility Card Item' : 'Utility Card Item',
          context: analytics ? analytics?.context + '|' + title : title
        }}
        href={link?.href}
        label={link?.label}
        target={link?.target}
        theme={cmpTheme?.link}
      >
        {image && (
          <div className={styles.image}>
            <Framework.Image
              altText={image?.altText}
              defaultAspectRatio={image?.defaultAspectRatio || '4/3'}
              defaultSizes={{tiny: '110px'}}
              defaultSrc={image?.defaultSrc}
              defaultSrcSet={image?.defaultSrcSet}
              defaultWebPSrcSet={image?.defaultWebPSrcSet}
              mobileAspectRatio={image?.mobileAspectRatio}
              mobileSrc={image?.mobileSrc}
              mobileSrcSet={image?.mobileSrcSet}
              mobileWebPSrcSet={image?.mobileWebPSrcSet}
            />
          </div>
        )}
        {title && (
          <div className={styles.title}>
            <Framework.Text
              content={title}
              inline
              theme={cmpTheme?.title}
            />
          </div>
        )}
        {link?.label && <div className={styles.link}>
          <Framework.Text
            content={link?.label}
            inline
            theme={cmpTheme?.fakeLink}
          />
          <span className={styles['icon-arrow-animated']}><span></span></span>
        </div>}
      </Framework.Anchor>
    </div>
  )
}

NavigationUtilityCard.propTypes = {
  /**
   * Additional Class Name - passed from the parent Component
   * -- allows box model adjustments (margin, padding, position styles etc)
   * -- should not be used for typography or color adjustments (font family, font weight, text color etc)
   */
  addtClassName: PropTypes.string,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    component: PropTypes.string,
    context: PropTypes.string
  }),
  /**
   * Image
   */
  image: PropTypes.shape({
    altText: PropTypes.string,
    defaultSrc: PropTypes.string,
    mobileSrc: PropTypes.string
  }),
  /**
   * Link
   */
  link: PropTypes.shape({
    endIcon: PropTypes.string,
    href: PropTypes.string,
    label: PropTypes.string,
    startIcon: PropTypes.string,
    target: PropTypes.string
  }),
  /**
   * Title
   */
  title: PropTypes.string,
}

export default NavigationUtilityCard
