import React from 'react'
import PropTypes from 'prop-types'
import styles from './Anchor.module.scss'
import { Link } from 'react-router-dom'
import { Icon } from 'components/framework'
import Typography from 'components/scaffolding/Typography'
import { trackNavigation } from 'utils/analytics'

/**
 * The Anchor Component is used as a navigation element where the route within the app will change
 */
const Anchor = ({
  addtClassName,
  addtOnClick,
  analytics,
  endIcon,
  href,
  inline,
  isExternal,
  label,
  startIcon,
  target,
  theme,
  title,
  ...props
}) => {

  const anchorTheme = {
    outline: {
      color: 'text-color--inherit',
      font: 'font--gotham-medium',
      size: 'text-size--cta'
    },
    outlineDark: {
      color: 'text-color--inherit',
      font: 'font--gotham-medium',
      size: 'text-size--cta'
    },
    primary: {
      color: 'text-color--inherit',
      font: 'font--gotham-medium',
      size: 'text-size--cta'
    },
    primaryRed: {
      color: 'text-color--inherit',
      font: 'font--gotham-medium',
      size: 'text-size--cta'
    },
    secondary: {
      color: 'text-color--inherit',
      font: 'font--gotham-medium',
      size: 'text-size--cta'
    },
    text: {
      color: 'text-color--inherit',
      font: 'font--gotham-medium',
      size: 'text-size--cta'
    },
    textDark: {
      color: 'text-color--inherit',
      font: 'font--gotham-medium',
      size: 'text-size--cta'
    },
    textError: {
      color: 'text-color--red',
      font: 'font--gotham-book',
      size: 'text-size--large'
    },
    textFooter: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--regular'
    },
    textFooterHeader: {
      color: 'text-color--inherit',
      font: 'font--gotham-medium',
      size: 'text-size--large'
    },
    textFooterSmall: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--small'
    },
    textMainNavFaqLink: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--small'
    },
    textMainNavLink: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--navigation'
    },
    textMainNavLinkMobile: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'headline-text-size--x-small'
    },
    textMainNavLinkUtilityMobile: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--x-small'
    },
    textNavBack: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--small'
    },
    textNavL1: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--small'
    },
    textNavL2: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--small'
    },
    textNavL3: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--navigation'
    },
    textUtility: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--small'
    },
    utility: {
      color: 'text-color--inherit',
      font: 'font--gotham-medium',
      size: 'text-size--caps-accent'
    },
    utilityLink: {
      color: 'text-color--inherit',
      font: 'font--gotham-medium',
      size: 'text-size--small'
    },
    cardLink: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--large'
    },
    subNavigation: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--sub-navigation'
    },
    subNavigationSelected: {
      color: 'text-color--inherit',
      font: 'font--gotham-bold',
      size: 'text-size--sub-navigation'
    }
  }

  /* TODO: Implement: <NavLink> for navigation when active page is represented */

  const generateAnchorStyleSystemClassName = (style, type) => {
    const themeClassNames = 
      styles[type] + ' ' + styles[style]
      + (addtClassName ? ' ' + addtClassName : '')
      + (inline ? ' ' + styles.inline : '')
    return themeClassNames
  }

  const checkForExternalLink = () => {
    if (isExternal || target === '_blank') {
      return true
    }
    const specialLinks = ['http', '#', 'mailto:', 'data:']
    let specialLinkMatch = false
    specialLinks.map(entry => {
      if (href?.startsWith(entry)) {
        specialLinkMatch = true
      }
      return false
    })
    return specialLinkMatch
  }
  const isExternalLink = checkForExternalLink()
  const Tag = isExternalLink ? 'a' : Link
  const TagProps = isExternalLink ? { href: href || '/' } : { to: href || '/' }

  return (
    <Tag
      {...TagProps}
      className={
        generateAnchorStyleSystemClassName(theme.style, theme.type)
      }
      onClick={() => {
        addtOnClick()
        trackNavigation({
          component: analytics.component,
          context: analytics.context,
          destination: href,
          label: label
        })
      }}
      /*
      * Secure external links against reverse tabnabbing
      * https://owasp.org/www-community/attacks/Reverse_Tabnabbing
      */ 
      rel={target === '_blank' ? 'noopener noreferrer' : null}
      // Add ARIA button role if styled as a button
      role={theme.type === 'button' ? 'button' : null}
      target={target}
      title={title}
      {...props}
    >
      {(() => {
        switch (theme.type) {
          case 'wrapper':
            return (
              props.children
            )
          case 'button':
            return (
              <>
                {startIcon && <Icon
                  icon={startIcon}
                  size={20}
                />}
                <Typography
                  color={anchorTheme[theme?.style]?.color}
                  font={anchorTheme[theme?.style]?.font}
                  size={anchorTheme[theme?.style]?.size}
                >
                  {label}
                </Typography>
                {endIcon && <Icon
                  icon={endIcon}
                  size={20}
                />}
              </>
            )
          case 'link':
            return (
              <>
                {startIcon && <Icon
                  icon={startIcon}
                  size={24}
                />}
                <Typography
                  color={anchorTheme[theme?.style]?.color}
                  font={anchorTheme[theme?.style]?.font}
                  size={anchorTheme[theme?.style]?.size}
                >
                  {label}
                </Typography>
                {endIcon && <Icon
                  icon={endIcon}
                  size={24}
                />}
              </>
            )
          default:
        }
      })()}
    </Tag>
  )
}

Anchor.propTypes = {
  /**
   * Additional Class Name - passed from the parent Component
   * -- allows box model adjustments (margin, padding, position styles etc)
   * -- should not be used for typography or color adjustments (font family, font weight, text color etc)
   */
  addtClassName: PropTypes.string,
  /**
   * Additional On Click function
   */
  addtOnClick: PropTypes.func,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    component: PropTypes.string,
    context: PropTypes.string
  }),
  /**
   * End Icon - Optional icon to display after the label
   */
  endIcon: PropTypes.string,
  /**
   * Href
   */
  href: PropTypes.string,
  /**
   * Inline - If true, the anchor will be displayed inline
   */
  inline: PropTypes.bool,
  /**
   * isExternal - If true, the anchor will use an a element
   */
  isExternal: PropTypes.bool,
  /**
   * Label - Accepts text labels or SVGs
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Start Icon - Optional icon to display before the label
   */
  startIcon: PropTypes.string,
  /**
   * Target
   */
  target: PropTypes.oneOf(['', '_blank', '_parent', '_self', '_top']),
  /**
   * Design theme
   */
  theme: PropTypes.shape({
    style: PropTypes.oneOf([
      'none',
      'primary',
      'primaryRed',
      'outline',
      'outlineDark',
      'secondary',
      'text',
      'textDark',
      'textError',
      'textFooter',
      'textFooterHeader',
      'textFooterSmall',
      'textMainNavFaqLink',
      'textMainNavLink',
      'textMainNavLinkMobile',
      'textMainNavLinkUtilityMobile',
      'textNavBack',
      'textNavL1',
      'textNavL2',
      'textNavL3',
      'textUtility',
      'utility',
      'utilityLink',
      'cardLink',
      'subNavigation',
      'subNavigationSelected'
    ]),
    type: PropTypes.oneOf([
      'link',
      'button',
      'wrapper'
    ])
  }),
  /**
   * Anchor title
   */
  title: PropTypes.string
}

Anchor.defaultProps = {
  addtOnClick: () => { return },
  analytics: {
    component: '<Parent component container identifier>',
    context: '<Additional information to add context to avoid ambiguity>'
  },
  href: window.location.href,
  label: 'Missing anchor text',
  target: '_self',
  theme: {
    style: 'primaryRed',
    type: 'link'
  },
  title: ''
}

export default Anchor
