import React from 'react'
import { MapTo, withComponentMappingContext, AllowedComponentsContainer } from '@adobe/aem-react-editable-components'

const ExperienceFragmentVariationConfig = {
  emptyLabel: 'Experience Fragment',

  isEmpty: function (props) {
    return !props || !props.configured
  }
}

class AppXF extends AllowedComponentsContainer {
  /*
   * Overriding the @aem-react-editable-components method to pass in 
   * 'aemNoDecoration={true}' property to suppress wrapper divs
   * aem-react-editable-components/src/components/Container.tsx
   */
  connectComponentWithItem(ChildComponent, itemProps, itemKey) {
    const itemPath = this.getItemPath(itemKey)

    return (
      <ChildComponent
        {...itemProps}
        key={itemPath}
        cqPath={itemPath}
        isInEditor={this.props?.isInEditor}
        aemNoDecoration={true}
        containerProps={this.getItemComponentProps(itemProps, itemKey, itemPath)}
      />
    )
  }

  /*
   * Overriding the @aem-react-editable-components render to ensure 
   * responsive grid wrapper is suppressed if not in edit mode
   */
  render() {
    let renderScript

    // removing '&& this.props.aemNoDecoration'
    if (!this.props?.isInEditor){
      renderScript = (
        <React.Fragment>
          { this.childComponents }
        </React.Fragment>
      )
    } else {
      renderScript = (
        <div {...this.containerProps}>
          {this.childComponents}
          {this.placeholderComponent}
        </div>
      )
    }

    return renderScript
  }
}

export default MapTo('aramark/components/core/experiencefragment')(withComponentMappingContext(AppXF), ExperienceFragmentVariationConfig)
