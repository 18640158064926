import { MapTo, Container } from '@adobe/aem-react-editable-components'
import Navigation from './Navigation'

const NavigationEditableConfig = {
  emptyLabel: 'Navigation',

  isEmpty: function (props) {
    return !props || !props.configured
  }
}

class NavigationContainer extends Container {
  /*
   * Overriding the @aem-react-editable-components method to pass in 
   * 'aemNoDecoration={true}' property to suppress wrapper divs
   * aem-react-editable-components/src/components/Container.tsx
   */
  connectComponentWithItem(ChildComponent, itemProps, itemKey) {
    const itemPath = this.getItemPath(itemKey)

    return (
      <ChildComponent
        {...itemProps}
        key={itemPath}
        cqPath={itemPath}
        isInEditor={this.props?.isInEditor}
        aemNoDecoration={true}
        containerProps={this.getItemComponentProps(itemProps, itemKey, itemPath)}
      />
    )
  }

  render() {
    return (
        <Navigation
          {...this.props}
        >
          {this.childComponents}
        </Navigation>
    )
  }
}

export default MapTo('aramark/components/content/navigation')( NavigationContainer, NavigationEditableConfig)
