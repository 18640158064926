import { MapTo } from '@adobe/aem-react-editable-components'
import NavigationUtility from './NavigationUtility'

const NavigationUtilityEditableConfig = {
  emptyLabel: 'Navigation - Utility',

  isEmpty: function (props) {
    return !props || !props.configured
  },
}

export default MapTo('aramark/components/content/navigation-utility')(NavigationUtility, NavigationUtilityEditableConfig)
