//import withAsyncImport from 'utils/withAsyncImport'
import { MapTo } from "@adobe/aem-react-editable-components";
import withAsyncImport from "utils/withAsyncImport";

const Deselector = withAsyncImport(() => import("./Deselector"));

const DeselectorEditableConfig = {
  emptyLabel: "Deselector Tool",

  isEmpty: function (props) {
    return !props || !props.configured;
  },
};

export default MapTo("aramark/components/content/deselector")(
  Deselector,
  DeselectorEditableConfig
);
