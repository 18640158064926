import React from 'react'
import PropTypes from 'prop-types'
import styles from './Loader.module.scss'

/**
 * The Loader Component is used as...
 */
const Loader = ({
  addtClassName,
  fullscreen,
  loading,
  ...props
}) => {

  return (
    <>
      {loading && <div
        className={addtClassName ? (fullscreen ? styles.fullscreen : styles.inline) + ' ' + addtClassName : (fullscreen ? styles.fullscreen : styles.inline)}
        tabIndex='-1'
        {...props}
      >

        <span className={styles.hidden}>Loading...</span>

        <div className={styles.container}>
          <div className={styles.one}></div>
          <div className={styles.two}></div>
          <div className={styles.three}></div>
        </div>

        <svg xmlns='http://www.w3.org/2000/svg' version='1.1'>
          <defs>
            <filter id='svg-filter'>
              <feGaussianBlur in='SourceGraphic' stdDeviation='10' result='blur' />
              <feColorMatrix in='blur' mode='matrix' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7'/>
            </filter>
          </defs>
        </svg>

      </div>}
    </>
  )
}

Loader.propTypes = {
  /**
   * Additional Class Name - passed from the parent Component
   * -- allows box model adjustments (margin, padding, position styles etc)
   * -- should not be used for typography or color adjustments (font family, font weight, text color etc)
   */
  addtClassName: PropTypes.string,
  /**
   * Fullscreen takeover
   */
  fullscreen: PropTypes.bool,
  /**
   * Loading flag
   */
  loading: PropTypes.bool,
}

Loader.defaultProps = {
  fullscreen: false,
  loading: false
}

export default Loader
