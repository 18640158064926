import { MapTo } from '@adobe/aem-react-editable-components'
import withAsyncImport from 'utils/withAsyncImport'

const SocialMediaEmbed = withAsyncImport(() =>
  import('./SocialMediaEmbed')
)

const SocialMediaEmbedConfig = {
  emptyLabel: 'Social Media Embed',

  isEmpty: function (props) {
    return !props || !props.configured
  }
}

export default MapTo('aramark/components/content/social-media-embed')(SocialMediaEmbed, SocialMediaEmbedConfig)
