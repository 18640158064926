import React from 'react'
import { MapTo, withComponentMappingContext, AllowedComponentsContainer } from '@adobe/aem-react-editable-components'

const ContainerConfig = {
  emptyLabel: 'Container',

  isEmpty: function (props) {
    return !props || !props.cqItemsOrder || props.cqItemsOrder.length === 0
  }
}

class AppContainer extends AllowedComponentsContainer {
  /*
   * Overriding the @aem-react-editable-components method to pass in 
   * 'aemNoDecoration={true}' property to suppress wrapper divs
   * aem-react-editable-components/src/components/Container.tsx
   */
  connectComponentWithItem(ChildComponent, itemProps, itemKey) {
    const itemPath = this.getItemPath(itemKey)

    return (
      <ChildComponent
        {...itemProps}
        key={itemPath}
        cqPath={itemPath}
        isInEditor={this.props?.isInEditor}
        aemNoDecoration={true}
        containerProps={this.getItemComponentProps(itemProps, itemKey, itemPath)}
      />
    )
  }
}

export default MapTo('aramark/components/structure/container')(withComponentMappingContext(AppContainer), ContainerConfig)
