import PropTypes from 'prop-types'
import * as icons from 'assets/icons'

/**
 * The Icon Component is used to display a pre-defined icon
 */
const Icon = ({
    icon,
    ...props
  }) => {

    const Icon = icons[icon]

    return (
      <>
        {Icon}
      </>
    )
}

Icon.propTypes = {
  /**
   * Icon - the name of the icon that will be shown.
   */
  icon: PropTypes.string
}

Icon.defaultProps = {}

export default Icon
