import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useScrollPosition } from 'hooks/useScrollPosition'
import { trackScrollDepth } from 'utils/analytics'

// Component that handles page scrolling reset on route change
const PageScrollingResetOnRouteChange = () => {
  
  const { pathname } = useLocation()
  const percentTracked = useRef(0)
  const targetThresholds = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  const pageThresholdStatus = useRef(Array.apply(null, { length: 10 }))

  useScrollPosition(({ previous, current }) => {
    const scrollDepthMaximum = document.getElementById('aramark').clientHeight
    const pageScroll = Math.ceil(((current.y / (scrollDepthMaximum - (window.innerHeight))) * 100) / 10 * 10)

    if (pageScroll >= percentTracked.current) {
      for (let i = 0; i < targetThresholds.length; i++) {
        if ((pageScroll >= targetThresholds[i]) && (pageThresholdStatus.current[i] === undefined)) {
          pageThresholdStatus.current[i] = true
          trackScrollDepth(parseInt(targetThresholds[i]))
          break
        }
      }
      percentTracked.current = pageScroll
    }
  })

  // Reset pageThresholdStatus & percentTracked on pathname change
  useEffect(() => {
    const resetThresholdStatus = () => {
      pageThresholdStatus.current = Array.apply(null, { length: 10 })
      percentTracked.current = 0
    }
    let resetAfterTimer = setTimeout(() => resetThresholdStatus(), 1000)
    return () => {
      clearTimeout(resetAfterTimer)
    }
  }, [pathname])
  
  // Renders nothing, since nothing is needed
  return null
}

export default PageScrollingResetOnRouteChange
