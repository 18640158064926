import React from 'react'
import { MapTo, withComponentMappingContext, ResponsiveGrid } from '@adobe/aem-react-editable-components'

const ResponsiveGridConfig = {
  emptyLabel: 'Layout Container',

  isEmpty: function (props) {
    return props.cqItemsOrder && props.cqItemsOrder.length > 0
  }
}

class AppResponsiveGrid extends ResponsiveGrid {
  /*
   * Overriding the @aem-react-editable-components method to pass in 
   * 'aemNoDecoration={true}' property to suppress wrapper divs
   * aem-react-editable-components/src/components/Container.tsx
   */
  connectComponentWithItem(ChildComponent, itemProps, itemKey) {
    const itemPath = this.getItemPath(itemKey)

    return (
      <ChildComponent
        {...itemProps}
        key={itemPath}
        cqPath={itemPath}
        isInEditor={this.props?.isInEditor}
        aemNoDecoration={true}
        containerProps={this.getItemComponentProps(itemProps, itemKey, itemPath)}
      />
    )
  }

  /*
   * Overriding the @aem-react-editable-components render to wrap 
   * content in main element
   */
  render() {
    const { allowedComponents, isInEditor } = this.props
    const isRootComponent = this.props?.cqPath?.endsWith('/jcr:content/root')

    if (isInEditor && allowedComponents && allowedComponents?.applicable) {
      return super.render() // render the allowed components markup in aem editor using the parent responsive grid render
    } else {
      let renderScript
      if (!this.props?.isInEditor && this.props?.aemNoDecoration){
        renderScript = (
          isRootComponent
          ?
          <React.Fragment>
            { this.childComponents }
          </React.Fragment>
          :
          <main>
            { this.childComponents }
          </main>
        )
      } else {
        renderScript = (
          <div {...this.containerProps}>
            {this.childComponents}
            {this.placeholderComponent}
          </div>
        )
      }

      return renderScript
    }
  }
}

export default MapTo('wcm/foundation/components/responsivegrid')(withComponentMappingContext(AppResponsiveGrid), ResponsiveGridConfig)
