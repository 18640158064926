import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styles from './Select.module.scss'
import * as Framework from 'components/framework'

/**
 * The Select Component is used as...
 */
const Select = React.forwardRef(({
  addtClassName,
  analytics,
  disabled,
  error,
  id,
  isSubmitted,
  isValid,
  label,
  name,
  onChange,
  options,
  required,
  theme,
  type,
  ...props
}, ref) => {
  // This is passed to the DOM so CSS can keep track of the currently selected option
  const [currentValue, setCurrentValue] = useState('')

  const cmpTheme = {
    label: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--large'
    },
    error: {
      color: 'text-color--red',
      font: 'font--gotham-book',
      size: 'text-size--large'
    }
  }

  return (
    // The component is wrapped in a container to group it with error and label components
    <label
      className={
        styles[theme] +
        (addtClassName ? ' ' + addtClassName : '')
      }
      id={`${id}-wrapper`}
    >
      {error && (
        <Framework.Text
          className={styles['error']}
          content={error}
          // If the input has a unique id, generate one for the error message as well
          id={`${id}-error`}
          inline
          role='alert'
          theme={cmpTheme.error}
        />
      )}
      <select
        // If an error is present and the input has a unique id, the aria-describedby attribute is added
        aria-describedby={error && id ? `${id}-error` : null}
        aria-disabled={disabled}
        aria-invalid={Boolean(error)}
        className={
          styles.input +
          (currentValue !== '' ? ' ' + styles['input-dirty'] : '') +
          (isSubmitted && isValid ? ' ' + styles['input-success'] : '') +
          (error ? ' ' + styles['input-error'] : '')
        }
        currentvalue={currentValue}
        defaultValue={''}
        disabled={disabled}
        id={`${id}-input`}
        name={name}
        onChange={(event) => {
          // Update the current value on the DOM so the CSS transitions work correctly
          setCurrentValue(event.target.value)
          onChange(event)
        }}
        ref={ref}
        required={required}
        // Additional props are added directly to the select element
        {...props}
      >
        {/* Set default empty option */}
        <option value='' disabled>{theme === 'tertiary' ? (required ? '*' + label : label) : ''}</option>
        {/* Set select options */}
        {options?.map((option, index) => (
          <option
            id={`${id}-${index}`}
            key={`${id}-${index}`}
            value={option?.value}
            disabled={option?.disabled}
          >
            {option?.label}
          </option>
        ))}
      </select>
      {label && theme !== 'tertiary' && (
        <Framework.Text
          className={
            styles.label +
            (isSubmitted && isValid ? ' ' + styles['label-success'] : '') +
            (error ? ' ' + styles['label-error'] : '')
          }
          content={required ? '*' + label : label}
          id={`${id}-label`}
          inline
          theme={cmpTheme.label}
        />
      )}
    </label>
  )
})

Select.propTypes = {
  /**
   * Additional Class Name - passed from the parent Component
   * -- allows box model adjustments (margin, padding, position styles etc)
   * -- should not be used for typography or color adjustments (font family, font weight, text color etc)
   */
   addtClassName: PropTypes.string,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    action: PropTypes.string,
    component: PropTypes.string,
    context: PropTypes.string,
    label: PropTypes.string
  }),
  /**
   * Disabled - disables the input
   */
  disabled: PropTypes.bool,
  /**
   * Error - error message to display
   */
  error: PropTypes.string,
  /**
   * ID - unique identifier for the input
   */
  id: PropTypes.string.isRequired,
  /**
   * Check if the field has already been submitted for validation
   */
  isSubmitted: PropTypes.bool,
  /**
   * Check if the field is valid
   */
  isValid: PropTypes.bool,
  /**
   * Select label
   */
  label: PropTypes.string,
  /**
   * Optional change handler
   */
  onChange: PropTypes.func,
  /**
   * Options - array of objects with id, label and value properties
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  /**
   * Design theme
   */
  theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary']).isRequired,
  /**
   * Name of the input
   */
  name: PropTypes.string,
  /**
   * Required - adds a required attribute to the input
   */
  required: PropTypes.bool,
}

Select.defaultProps = {
  analytics: {
    action: '<Purpose of the interaction>',
    component: '<Parent component container identifier>',
    context: '<Additional information to add context to avoid ambiguity>',
    label: '<Text displayed within the button element>'
  },
  theme: 'primary'
}

Select.name = 'FormElement.select'
export default Select
