import { MapTo } from '@adobe/aem-react-editable-components'
import withAsyncImport from 'utils/withAsyncImport'

const Form = withAsyncImport(() =>
  import('./Form')
)

const FormEditableConfig = {
  emptyLabel: 'Form',

  isEmpty: function (props) {
    return !props || !props.configured
  }
}

export default MapTo('aramark/components/content/form')(Form, FormEditableConfig)
