import React from 'react'
import PropTypes from 'prop-types'
import styles from './TextfieldNumber.module.scss'
import * as Framework from 'components/framework'

/**
 * The Textfield Component is used as...
 */
const TextfieldNumber = React.forwardRef(({
  addtClassName,
  analytics,
  disabled,
  error,
  id,
  isSubmitted,
  isValid,
  label,
  name,
  onChange,
  required,
  theme,
  type,
  fieldDesc,
  ...props
}, ref) => {
  const cmpTheme = {
    label: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--large'
    },
    error: {
      color: 'text-color--red',
      font: 'font--gotham-book',
      size: 'text-size--large'
    }
  }

  const onChangeVal = (e) => {
    if (type !== 'text') {
      const val = e;
      val.target.value = e.target.value.replace(/[^0-9]/g, "");
      onChange(val);
    } else {
      onChange(e);
    }
  }

  return (
    // The component is wrapped in a container to group it with error and label components
    <>
      {fieldDesc && <Framework.Text
        className={styles.descLabel}
        content={fieldDesc}
        inline
        theme={cmpTheme.label}
      />
      }
      <label
        className={
          styles[theme]
          + (addtClassName ? ' ' + addtClassName : '')
          + ((type === 'hidden' || props?.hidden) ? ' ' + styles.hidden : '')
        }
        id={`${id}-wrapper`}
      >
        {(error && type !== 'hidden') && (
          <Framework.Text
            className={styles['error']}
            content={error}
            id={`${id}-error`}
            inline
            role='alert'
            theme={cmpTheme.error}
          />
        )}
        <input
          // If an error is present, the aria-describedby attribute is added
          aria-describedby={error ? `${id}-error` : null}
          aria-disabled={disabled}
          aria-invalid={Boolean(error)}
          aria-placeholder={label}
          className={
            styles.input +
            (isSubmitted && isValid ? ' ' + styles['input-success'] : '') +
            (error ? ' ' + styles['input-error'] : '')
          }
          disabled={disabled}
          id={`${id}-input`}
          name={name}
          onChange={onChangeVal}
          placeholder={label}
          ref={ref}
          required={required}
          type={type ?? 'text'}
          oninput={type !== 'text' ? "event.target.value = event.target.value.replace(/[^0-9]*/g,'');" : ''}
          // Additional props are added directly to the input element
          {...props}
        />
        {(label && type !== 'hidden') && (
          <Framework.Text
            className={
              styles.label +
              (isSubmitted && isValid ? ' ' + styles['label-success'] : '') +
              (error ? ' ' + styles['label-error'] : '')
            }
            content={required ? '*' + label : label}
            id={`${id}-label`}
            inline
            theme={cmpTheme.label}
          />
        )}
      </label>
    </>
  )
})

TextfieldNumber.propTypes = {
  /**
   * Additional Class Name - passed from the parent Component
   * -- allows box model adjustments (margin, padding, position styles etc)
   * -- should not be used for typography or color adjustments (font family, font weight, text color etc)
   */
  addtClassName: PropTypes.string,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    action: PropTypes.string,
    component: PropTypes.string,
    context: PropTypes.string,
    label: PropTypes.string
  }),
  /**
   * Disabled - disables the input
   */
  disabled: PropTypes.bool,
  /**
   * Error - error message to display
   */
  error: PropTypes.string,
  /**
   * ID - unique identifier for the textfield
   */
  id: PropTypes.string.isRequired,
  /**
   * Check if the field has already been submitted for validation
   */
  isSubmitted: PropTypes.bool,
  /**
   * Check if the field is valid
   */
  isValid: PropTypes.bool,
  /**
   * Textfield label
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional change handler
   */
  onChange: PropTypes.func,
  /**
   * Design theme
   */
  theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary']).isRequired,
  /**
   * Name of the input
   */
  name: PropTypes.string,
  /**
   * Required - adds a required attribute to the input
   */
  required: PropTypes.bool,
  /**
   * Type of the input
   */
  type: PropTypes.oneOf([
    'text',
    'email',
    'password',
    'number',
    'tel',
    'url',
    'search',
    'hidden'
  ]).isRequired,
}

TextfieldNumber.defaultProps = {
  analytics: {
    action: '<Purpose of the interaction>',
    component: '<Parent component container identifier>',
    context: '<Additional information to add context to avoid ambiguity>',
    label: '<Text displayed within the button element>'
  },
  isValid: false,
  theme: 'primary',
  type: 'text'
}

TextfieldNumber.name = 'FormElement.TextfieldNumber'
export default TextfieldNumber
