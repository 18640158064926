import { useEffect } from 'react'

// Component that handles loading fonts (via FOUT with Class method)
const FontLoading = () => {
  // Adds 'fonts-loaded' class to HTML element
  useEffect(() => {
    if (sessionStorage.fontsLoaded) {
			document.documentElement.className += ' fonts-loaded'
    } else {
      if ('fonts' in document) {
        Promise.all([
          document.fonts.load('350 1em HCo Gotham SSm'),
          document.fonts.load('500 1em HCo Gotham SSm'),
          document.fonts.load('700 1em HCo Gotham SSm'),
          document.fonts.load('400 1em RecklessNeue'),
          document.fonts.load('italic 400 1em RecklessNeue')
        ]).then(() => {
          document.documentElement.className += ' fonts-loaded'
          sessionStorage.fontsLoaded = true
        })
      }
    }
  }, [])
  
  // Renders nothing, since nothing is needed
  return null
}

export default FontLoading
