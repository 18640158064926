import { MapTo } from '@adobe/aem-react-editable-components'
import withAsyncImport from 'utils/withAsyncImport'

const StatementWithImage = withAsyncImport(() =>
  import('./StatementWithImage')
)

const StatementWithImageEditableConfig = {
  emptyLabel: 'StatementWithImage',

  isEmpty: function (props) {
    return !props || !props.configured
  }
}

export default MapTo('aramark/components/content/statement-with-image')(StatementWithImage, StatementWithImageEditableConfig)
 