import { MapTo } from '@adobe/aem-react-editable-components'
import withAsyncImport from 'utils/withAsyncImport'

const Banner = withAsyncImport(() =>
  import('./Banner')
)

const BannerEditableConfig = {
  emptyLabel: 'Banner',

  isEmpty: function (props) {
    return !props || !props.configured
  }
}

export default MapTo('aramark/components/content/banner')(Banner, BannerEditableConfig)
