import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// Component that attaches scroll to top handler on route change
const ScrollToTopOnRouteChange = () => {
  const { pathname } = useLocation()

  // Scroll To Top of Page on pathname change
  useEffect(() => {
    try {
      // Trying to use https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'auto',
      })
    } catch (error) {
      // Fallback for older browsers
      window.scrollTo(0, 0)
    }
  }, [pathname])
  
  // Renders nothing, since nothing is needed
  return null
}

export default ScrollToTopOnRouteChange
