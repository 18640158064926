export default <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
>
    <g id="surface1">
        <path
            fill="rgb(0%,0%,0%)"
            style={{
                stroke: "none",
                fillRule: "nonzero",
                fillOpacity: 1,
            }}
            d="M 11.988281 19.039062 C 11.324219 19.039062 10.78125 18.515625 10.753906 17.855469 L 10.0625 2.140625 C 10.015625 1.046875 10.890625 0.132812 11.988281 0.132812 C 13.082031 0.132812 13.957031 1.046875 13.910156 2.140625 L 13.21875 17.855469 C 13.191406 18.515625 12.648438 19.039062 11.988281 19.039062 Z M 11.988281 19.039062 "
        />
        <path
            fill="rgb(0%,0%,0%)"
            style={{
                stroke: "none",
                fillRule: "nonzero",
                fillOpacity: 1,
            }}
            d="M 13.46875 22.359375 C 13.46875 23.175781 12.804688 23.839844 11.988281 23.839844 C 11.167969 23.839844 10.507812 23.175781 10.507812 22.359375 C 10.507812 21.539062 11.167969 20.878906 11.988281 20.878906 C 12.804688 20.878906 13.46875 21.539062 13.46875 22.359375 Z M 13.46875 22.359375 "
        />
    </g>
</svg>
