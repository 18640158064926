import React from 'react'
import PropTypes from 'prop-types'
import styles from './NavigationUtilityList.module.scss'
import * as Framework from 'components/framework'

const NavigationUtilityList = ({
  addtClassName,
  analytics,
  header,
  linkCountry,
  ...props
}) => {

  const cmpTheme = {
    header: {
      color: 'text-color--black',
      font: 'font--gotham-bold',
      size: 'text-size--small'
    },
    link: {
      style: 'textUtility',
      type: 'link'
    }
  }

  // TODO: add better active link status detection when URLs are known
  // addtClassName={(link?.link.indexOf(window.location.hostname) !== -1) ? styles.active : ''}
  // TODO: implement open in new window property from model.json

  return (
    <div
      className={
        addtClassName ? addtClassName + ' ' + styles.list : styles.list
      }
      {...props}
    >
      {header && <div className={styles.header}>
        <Framework.Text
          content={header}
          theme={cmpTheme?.header}
        />
      </div>}
      {(linkCountry?.length > 0) && <ul className={styles.links}>
        {linkCountry?.map((link, index) => (
          <li key={index}>
            <Framework.Anchor
              addtClassName={(link?.link.indexOf(window.location.hostname + '/') !== -1) ? styles.active : ''}
              analytics={{
                component: analytics ? analytics?.component + '|Utility List Item' : 'Utility List Item',
                context: analytics ? analytics?.context + '|' + header : header
              }}
              href={link?.link}
              label={link?.label}
              target='_blank'
              theme={cmpTheme?.link}
            />
          </li>
        ))}
      </ul>}
    </div>
  )
}

NavigationUtilityList.propTypes = {
  /**
   * Additional Class Name - passed from the parent Component
   * -- allows box model adjustments (margin, padding, position styles etc)
   * -- should not be used for typography or color adjustments (font family, font weight, text color etc)
   */
  addtClassName: PropTypes.string,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    component: PropTypes.string,
    context: PropTypes.string
  }),
  /**
   * Header
   */
  header: PropTypes.string,
  /**
   * Array of Country Links
   */
  linkCountry: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      label: PropTypes.string
    })
  )
}

NavigationUtilityList.defaultProps = {}

export default NavigationUtilityList
