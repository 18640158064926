import { useState } from 'react'

import { useInterval } from 'hooks/useInterval'

import { useSpringCarousel, useTransitionCarousel } from 'react-spring-carousel-js'

/**
 * The useCarousel hook controls the carousel implementation, additional docs can be found here:
 * https://herodigital.atlassian.net/wiki/spaces/ARR/pages/2574155834/Implementation+Carousel
 * 
 * @param {number}  [autoplay] - The autoplay speed in milliseconds
 * @param {string}  [carouselSlideAxis='x'] - The carousel slide axis (x or y)
 * @param {number}  [initialActiveItem=0] - The initial active item
 * @param {string}  [initialStartingPosition='center'] - The initial starting position (start, center, end)
 * @param {object}  items - The carousel items
 * @param {number}  [itemsPerSlide=1] - The number of items per slide
 * @param {object}  [springAnimationProps] - Custom react-spring props for the useTransitionCarousel hook
 * @param {object}  [springConfig] - Custom animation config for the useSpringCarousel hook
 * @param {string}  [thumbsSlideAxis='x'] - The thumbs slide axis (x or y)
 * @param {object}  [toNextItemSpringProps] - Custom react-spring props for the useTransitionCarousel hook
 * @param {object}  [toPrevItemSpringProps] - Custom react-spring props for the useTransitionCarousel hook
 * @param {string}  [type='spring'] - The carousel animation type (fade or spring)
 * @param {boolean} [withLoop=false] - Whether or not to loop the carousel
 * @param {boolean} [withThumbs=false] - Whether or not to display thumbnails
 */
export const useCarousel = ({
  autoplay,
  carouselSlideAxis,
  initialActiveItem,
  initialStartingPosition,
  items,
  itemsPerSlide,
  springAnimationProps,
  springConfig,
  thumbsSlideAxis,
  toNextItemSpringProps,
  toPrevItemSpringProps,
  type = 'spring',
  withLoop,
  withThumbs
}) => {
  // Type of animation hook to use, defaults to spring
  const useHook = type === 'fade' ? useTransitionCarousel : useSpringCarousel
  const {
    carouselFragment,
    getCurrentActiveItem,
    getIsActiveItem,
    getIsAnimating,
    getIsDragging,
    getIsNextItem,
    getIsPrevItem,
    slideToItem,
    slideToNextItem,
    slideToPrevItem,
    thumbsFragment,
    useListenToCustomEvent,
  } = useHook({
    carouselSlideAxis: carouselSlideAxis || 'x',
    initialActiveItem: initialActiveItem || 0,
    initialStartingPosition: (itemsPerSlide % 2 === 0 && initialStartingPosition) ? initialStartingPosition : null,
    items: items || [],
    itemsPerSlide: itemsPerSlide || 1,
    // Is it's a spring carousel with custom spring config
    ...(type === 'spring' && springConfig) ? {springConfig} : {},
    // If it's a transition carousel with custom transition props
    ...(type === 'fade' && springAnimationProps) ? {springAnimationProps} : {},
    ...(type === 'fade' && toPrevItemSpringProps) ? {toPrevItemSpringProps} : {},
    ...(type === 'fade' && toNextItemSpringProps) ? {toNextItemSpringProps} : {},
    thumbsSlideAxis: thumbsSlideAxis || 'x',
    withLoop: withLoop || false,
    withThumbs: withThumbs || false
  })
  const [paused, setPaused] = useState(false)

  // Handle autoplay if active
  const {reset, stop} = useInterval(() => {
    if (autoplay) slideToNextItem()
  }, autoplay)

  // Toggle carousel pause
  const togglePause = () => {
    if (!paused) {
      setPaused(true)
      stop()
    } else {
      setPaused(false)
      reset()
    }
  }

  // Manually pause carousel
  const pauseCarousel = () => {
    setPaused(true)
    stop()
  }

  // Manually start carousel
  const startCarousel = () => {
    setPaused(false)
    reset()
  }

  // Return paused value
  const getIsPaused = () => {
    return paused
  }

  return {
    carouselFragment, // The carousel fragment containing slides
    getCurrentActiveItem, // The current active item
    getIsActiveItem, // Get whether or not the referenced item is active
    getIsAnimating, // Get whether or not the carousel is animating
    getIsDragging, // Get whether or not the carousel is being dragged
    getIsNextItem, // Get whether or not the referenced item is the next item
    getIsPaused, // Whether or not the carousel is paused
    getIsPrevItem, // Get whether or not the referenced item is the previous item
    paused, // Check if carousel is paused
    pauseCarousel, // Manually pause carousel
    reset, // Reset the carousel timer
    slideToItem, // Move carousel to specific item
    slideToNextItem, // Move carousel to next item
    slideToPrevItem, // Move carousel to previous item
    startCarousel, // Manually start carousel
    stop, // Stop timer
    thumbsFragment, // The thumbs fragment containing thumbs
    togglePause, // Toggle pause
    // Listen to custom events (onSlideStartChange, onSlideChange, onFullscreenChange,onDrag, onLeftSwipe, onRightSwipe)
    useListenToCustomEvent
  }
}
