//import withAsyncImport from 'utils/withAsyncImport'
import { MapTo } from "@adobe/aem-react-editable-components";
import withAsyncImport from "utils/withAsyncImport";

const DeselectorEmailCapture = withAsyncImport(() =>
  import("./DeselectorEmailCapture")
);

const DeselectorEmailCaptureEditableConfig = {
  emptyLabel: "Deselector Email Capture Tool",

  isEmpty: function (props) {
    return !props || !props.configured;
  },
};

export default MapTo("aramark/components/content/deselector-email-capture")(
  DeselectorEmailCapture,
  DeselectorEmailCaptureEditableConfig
);
