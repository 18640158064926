const AramarkLogoBlackRed = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 63.3" >
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        mix-blend-mode: multiply;\n      }\n\n      .cls-2, .cls-3 {\n        stroke-width: 0px;\n      }\n\n      .cls-4 {\n        isolation: isolate;\n      }\n\n      .cls-3 {\n        fill: #ea002a;\n      }\n    "
        }
      </style>
    </defs>
    <g className="cls-4">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g>
            <g className="cls-1">
              <g>
                <path
                  className="cls-3"
                  d="m221.81,14.78c2.31,0,4.52-1.11,5.91-2.96.97-1.29,1.48-2.82,1.48-4.43s-.51-3.14-1.48-4.43c-1.39-1.85-3.6-2.96-5.91-2.96-4.08,0-7.39,3.31-7.39,7.39s3.32,7.39,7.39,7.39"
                />
                <path
                  className="cls-3"
                  d="m249.95,15.22c-.08-.1-.25-.11-.44-.05-.04.01-.07.03-.1.04-5.55,2.03-17.77,4.25-34.22,1.33-19.59-3.48-26.63-1.73-30.08-.47-.03.01-.06.02-.09.03-.21.09-.34.22-.33.36,0,.14.16.26.38.32.01,0,.02,0,.03,0,24.74,5.56,9.43,26.24-24.35,45.75-.06.03-.12.06-.17.1-.19.13-.29.3-.26.43.05.16.27.24.56.23.03,0,.05,0,.08,0,5.89-.53,14.57-3.68,24.99-8.32,0,0,0,0,.01,0,1.22-.54,2.46-1.1,3.72-1.68,5.27-2.3,11.24-4.46,15.04-3.27,5,1.58,5.96,5.3,6.56,12.37,0,.04,0,.07.01.11.02.18.1.32.21.35.13.04.28-.05.41-.22.01-.02.03-.04.05-.06,2.43-3.34,11.45-16.41,11.96-26.72,12.12-7.03,22.23-14.23,25.88-20,.05-.06.09-.13.12-.19.09-.17.1-.34.02-.44"
                />
              </g>
              <g>
                <path
                  className="cls-3"
                  d="m221.06,56.39h-.38v.51h.39c.2,0,.3-.1.3-.26,0-.17-.12-.26-.31-.26m.32.78l.43.63h-.45l-.37-.57h-.31v.57h-.39v-1.76h.78c.42,0,.69.21.69.58,0,.29-.16.47-.38.55"
                />
                <path
                  className="cls-3"
                  d="m220.97,55.57c-.77,0-1.39.62-1.39,1.39s.62,1.39,1.39,1.39,1.39-.62,1.39-1.39-.62-1.39-1.39-1.39m0,3.14c-.97,0-1.75-.78-1.75-1.75s.79-1.75,1.75-1.75,1.75.78,1.75,1.75-.79,1.75-1.75,1.75"
                />
              </g>
            </g>
            <g>
              <path
                className="cls-2"
                d="m131.76,18.9v2.13c-1.43-1.02-3.08-1.76-4.87-2.13-.85-.17-1.73-.27-2.63-.27s-1.79.09-2.64.27c-5.87,1.21-10.28,6.39-10.32,12.61,0,.02,0,.05,0,.08s0,.05,0,.08c.04,7.12,5.82,12.87,12.95,12.87,2.8,0,5.39-.89,7.5-2.39v2.12h5.45v-25.37h-5.45Zm-7.5,20.19c-4.14,0-7.5-3.36-7.5-7.5s3.36-7.5,7.5-7.5,7.5,3.36,7.5,7.5-3.36,7.5-7.5,7.5Z"
              />
              <path
                className="cls-2"
                d="m63.7,18.9v2.13c-1.43-1.02-3.09-1.76-4.87-2.13-.85-.17-1.73-.27-2.64-.27s-1.78.09-2.63.27c-3.43.71-6.36,2.77-8.21,5.6-1.31,2-2.08,4.39-2.11,6.95,0,.04,0,.09,0,.13s0,.09,0,.13c.03,2.57.8,4.95,2.11,6.95,2.31,3.53,6.31,5.87,10.84,5.87,2.8,0,5.39-.89,7.51-2.39v2.12h5.45v-25.37h-5.45Zm-7.51,20.19c-4.14,0-7.5-3.36-7.5-7.5s3.36-7.5,7.5-7.5,7.51,3.36,7.51,7.5-3.36,7.5-7.51,7.5Z"
              />
              <path
                className="cls-2"
                d="m20.46,18.9v2.13c-1.43-1.02-3.08-1.76-4.87-2.13-.85-.17-1.73-.27-2.63-.27s-1.78.09-2.64.27C4.43,20.12,0,25.34,0,31.59c0,7.15,5.8,12.95,12.95,12.95,2.8,0,5.39-.89,7.5-2.39v2.12h5.45v-25.37h-5.45Zm-7.5,20.19c-4.15,0-7.51-3.36-7.51-7.5s3.36-7.5,7.51-7.5,7.5,3.36,7.5,7.5-3.36,7.5-7.5,7.5Z"
              />
              <path
                className="cls-2"
                d="m150.34,18.9c-1.48.37-2.8,1.12-3.87,2.13v-2.13h-5.45v25.37h5.45v-14.15h0c0-3.33,2.7-6.03,6.04-6.03h1.77v-5.45h-1.77c-.75,0-1.47.09-2.16.27Z"
              />
              <path
                className="cls-2"
                d="m100.9,18.9c-.75-.17-1.53-.27-2.34-.27-.08,0-.15,0-.22,0-.06,0-.12,0-.18,0h-.03c-2.85.13-4.99,1.46-6.15,2.39-.62.5-.96.88-.96.88,0,0-.45-.5-1.25-1.12-1.1-.85-2.85-1.9-4.95-2.12-.02,0-.04,0-.05,0-.04,0-.08,0-.12,0-.03,0-.06,0-.09,0-.03,0-.07,0-.1,0-.04,0-.08,0-.12,0-.02,0-.05,0-.07,0-.07,0-.13,0-.2,0-.76,0-1.48.09-2.17.27-1.3.34-2.46,1.02-3.5,2.05v-2.05h-5.45v25.37h5.45v-15.05c0-2.83,2.16-5.13,5.08-5.13s4.82,2.3,4.82,5.13v15.05h5.45v-15.05c0-2.83,2.11-5.13,4.95-5.13s4.68,2.3,4.68,5.13v15.05h5.45v-15.38c0-4.86-3.39-8.93-7.93-9.99Z"
              />
              <polygon
                className="cls-2"
                points="177.78 18.9 171.21 18.9 162.4 29.58 162.4 12.72 162.4 10.45 156.95 10.45 156.95 44.27 158.16 44.27 162.4 44.27 162.4 33.59 171.21 44.27 177.78 44.27 167.31 31.59 177.78 18.9"
              />
              <path
                className="cls-2"
                d="m39.03,18.9c-1.47.37-2.8,1.12-3.87,2.13v-2.13h-5.45v25.37h5.45v-13.66c0-3.91,2.54-6.53,6.44-6.53h1.36v-5.45h-1.77c-.75,0-1.47.09-2.16.27Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>


export {AramarkLogoBlackRed};