import React from 'react'
import PropTypes from 'prop-types'
import styles from './Image.module.scss'
import { useWindowSize } from 'hooks/useWindowSize'

/**
 * The Image Component is used to render a responsive and optimized image, 
 * which supports art direction and custom aspect ratios
 */
const Image = ({
  addtClassName,
  altText,
  defaultAspectRatio,
  defaultSizes,
  defaultSrc,
  defaultSrcSet,
  defaultWebPSrcSet,
  loading,
  mobileAspectRatio,
  mobileSizes,
  mobileSrc,
  mobileSrcSet,
  mobileWebPSrcSet,
  ...props
}) => {

  const { width } = useWindowSize()

  const sizesBreakpoints = {
    tiny: 320,
    small: 500,
    medium: 768,
    large: 992,
    xlarge: 1212,
    xxlarge: 1700
  }
  const sizesTemplate = {
    tiny: undefined,
    small: undefined,
    medium: undefined,
    large: undefined
  }
  const constructSizes = (sizesObj) => {
    let sizesArr = []
    let sizesAttr = '100vw'

    // if sizesObj contains values
    if (sizesObj && Object.keys(sizesObj).length > 0) {
      // overwrite the template values
      Object.assign(sizesTemplate, sizesObj)
    }

    // remove all undefined options
    Object.keys(sizesTemplate).forEach(key => sizesTemplate[key] === undefined && delete sizesTemplate[key])

    // if sizesTemplate contains values
    if (Object.keys(sizesTemplate).length > 0) {
      // generate a size value for each option
      for (const size in sizesTemplate) {
        sizesArr.push(
          `(min-width: ${sizesBreakpoints[size]}px) ${sizesTemplate[size]}`
        )
      }
      sizesArr.push('100vw')
      sizesAttr = sizesArr.join(',')
    }

    return sizesAttr
  }

  const srcSetTemplate = '{0} 480w, {1} 640w, {2} 800w, {3} 1180w, {4} 1440w, {5} 2400w'
  const constructSrcSet = (srcSetArray) => {
    if (srcSetArray && srcSetArray.length === 6) {
      let srcSet = srcSetTemplate
      for (let i = 0; i < srcSetArray.length; i++) {
        srcSet = srcSet.replace('{' + i + '}', srcSetArray[i])
      }
      return srcSet
    }
  }

  // TODO: Img should have role='presentation' if altText attribute not specified
  // TODO: Image initial sizing width and height properties?

  /* Current Renditions:
   *
   * 480/320
   * 640/428
   * 800/534
   * 1180/787
   * 1440/960
   * 2400/1600
   * 
   */

  return (
    <div
      className={
        styles.image + ' ' +
        styles['aspect-ratio-' + (width > sizesBreakpoints.medium
          ? defaultAspectRatio
          : mobileAspectRatio)?.replace('/','-')] + ' ' +
        addtClassName || ''
      }
      data-aspect-ratio={width > sizesBreakpoints.medium
        ? defaultAspectRatio
        : mobileAspectRatio
      }
      {...props}
    >
      <picture>
        <source
          media='(max-width: 768px)'
          sizes={constructSizes(mobileSizes)}
          srcSet={constructSrcSet(mobileWebPSrcSet || defaultWebPSrcSet)}
          type='image/webp'
        />
        <source
          media='(max-width: 768px)'
          sizes={constructSizes(mobileSizes)}
          srcSet={constructSrcSet(mobileSrcSet || defaultSrcSet)}
        />
        <source
          media='(min-width: 769px)'
          sizes={constructSizes(defaultSizes)}
          srcSet={constructSrcSet(defaultWebPSrcSet)}
          type='image/webp'
        />
        <source
          media='(min-width: 769px)'
          sizes={constructSizes(defaultSizes)}
          srcSet={constructSrcSet(defaultSrcSet)}
        />
        <img
          alt={altText}
          loading={loading}
          src={defaultSrc}
        />
      </picture>
    </div>
  )
}

Image.propTypes = {
  /**
   * Additional Class Name - passed from the parent Component
   * -- allows box model adjustments (margin, padding, position styles etc)
   * -- should not be used for typography or color adjustments (font family, font weight, text color etc)
   */
  addtClassName: PropTypes.string,
  /**
   * Alt Text
   */
  altText: PropTypes.string,
  /**
   * Default Aspect Ratio
   */
  defaultAspectRatio: PropTypes.oneOf([
    '1',
    '2/3',
    '3/2',
    '4/3',
    '9/16',
    '16/9',
    'banner',
    'banner-featured',
    'banner-full-width',
    'card',
    'card-feed',
    'card-featured',
    'card-wide',
    'carousel-card',
    'original'
  ]),
  /**
   * Default Image Responsive Sizes
   */
  defaultSizes: PropTypes.object,
  /**
   * Default Image Src
   */
  defaultSrc: PropTypes.string,
  /**
   * Default Image Srcset
   */
  defaultSrcSet: PropTypes.array,
  /**
   * Default WebP Image Srcset
   */
  defaultWebPSrcSet: PropTypes.array,
  /**
   * Loading -  // lazy or eager
   */
  loading: PropTypes.string,
  /**
   * Mobile Aspect Ratio
   */
  mobileAspectRatio: PropTypes.oneOf([
    '1',
    '2/3',
    '3/2',
    '4/3',
    '9/16',
    '16/9',
    'banner',
    'banner-featured',
    'banner-full-width',
    'card',
    'card-feed',
    'card-featured',
    'card-wide',
    'carousel-card',
    'original'
  ]),
  /**
   * Mobile Image Responsive Sizes
   */
  mobileSizes: PropTypes.object,
  /**
   * Mobile Image Src
   */
  mobileSrc: PropTypes.string,
  /**
   * Mobile Image Srcset
   */
  mobileSrcSet: PropTypes.array,
  /**
   * Mobile WebP Image Srcset
   */
  mobileWebPSrcSet: PropTypes.array
}

Image.defaultProps = {
  defaultAspectRatio: 'original',
  defaultSrc: '/placeholder.png',
  defaultSrcSet: [],
  loading: 'lazy'
}

export default Image
