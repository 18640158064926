  /**
   * Generates Style Modifier object from raw modifiers passed in model.json
   * 'appliedCssClassNames'
   *
   * @param {string} rawModifiers List of style modifiers authored on Component
   * @param {boolean} hasShape Component includes shape background
   * @returns {{ stlyeName: styleValue }} Object of keys and values
   */
   export const generateStyleModifiers = (rawModifiers, hasShape) => {

    const modifiers = {}

    if (rawModifiers === undefined) return modifiers

    /**
     * Converting dash-case keys to camelCase keys
     */
    const _getKey = (rawKey) => {
      return rawKey.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase() })
    }

    /** 
     * Element prefixes that need replacing to match style names
     * - All | all-text-color--
     * - Description | description-text-color-- | description-font--
     * - Eyebrow | eyebrow-text-color-- | eyebrow-font--
     * - Headline | headline-text-color-- | headline-font--
     * - Primary Text | primary-text-color--
     * - Primary CTA | primary-cta--
     * - Secondary Text | secondary-text-color--
     * - Secondary CTA | secondary-cta--
     * - Overlay theme | overlay--
     */
    const _getValue = (rawValue) => {
      const prefixes = [
        'alignment--',
        'all-',
        'cta-presentation--',
        'description-',
        'eyebrow-',
        'headline-',
        'primary-cta--', // needs to be before primary- so it matches first
        'primary-',
        'secondary-cta--', // needs to be before secondary- so it matches first
        'secondary-',
        'spacing-above--',
        'spacing-below--',
      ]
      const prefixBeGone = new RegExp(prefixes.join('|'), 'gi')
      return rawValue.replace(prefixBeGone, '')
    }

    const modifierOptions = rawModifiers.split(' ')
    for (const mod of modifierOptions) {
      if (mod.indexOf('--') !== -1) {
        const override = mod.split('--')
        modifiers[_getKey(override[0])] = _getValue(mod)
      }
    }

    return modifiers
  }

  // Backwards compatibility between background-color and shape-color
  export const getShapeColor = (color) => {
    if (color) {
      return color.replace('background-color-', 'shape-color-')
    }
    return null
  }
