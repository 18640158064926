import { useEffect, useRef, useCallback } from 'react'

/**
 * The useInterval hook makes it easier to work with multiple intervals in React.
 */
export const useInterval = (callback, delay) => {
  const savedCallback = useRef(null)
  const interval = useRef(null)

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval
  useEffect(() => {
    const tick = () => {
      savedCallback.current()
    }
    if (delay !== null) {
      interval.current = setInterval(tick, delay)
      return () => clearInterval(interval.current)
    }
  }, [delay])

  // Clear interval on component removal
  useEffect(() => {
    return () => clearInterval(interval.current)
  }, [])

  // Reset the interval
  const reset = useCallback(() => {
    const tick = () => {
      savedCallback.current()
    }
    if (interval.current !== null) {
      clearInterval(interval.current)
      interval.current = setInterval(tick, delay)
    }
  }, [delay])

  // Stop the interval
  const stop = useCallback(() => {
    if (interval.current !== null) {
      clearInterval(interval.current)
    }
  }, [])

  return {
    reset,
    stop
  }
}
