import React from 'react'
import { Helmet } from 'react-helmet'

export const PageMeta = props => {
  const {
    canonicalUrl,
    cmpI18n,
    language,
    robotsTags,
    seoDescription,
    seoImage,
    seoImageAlt,
    seoTitle
  } = props

  /* Sample Page cmpI18n payload
  const cmpI18n = {
    defaultAuthor: 'Aramark',
    defaultDescription: 'Regardless of what we do or where we work, we are all united in our passion for serving others. It is our heritage and our legacy. And it shines through in our teams and people who think bigger, collaborate seamlessly, and take hospitality one step further.',
    defaultImage: '/aramark-logo.svg',
    defaultImageAlt: 'Aramark brand logo',
    defaultLanguage: 'en',
    defaultLocaleVariant: 'en_US',
    defaultOrganization: 'Aramark (NYSE: ARMK)',
    defaultTitle: 'Aramark: A passion for hospitality',
    defaultTwitterAuthor: '@Aramark',
    facebookAppId: '1234567',
    siteName: 'Aramark',
    siteUrl: 'https://www.aramark.com',
    twitterHandle: '@Aramark'
  }
  */

  /* General Configuration */
  const metaAuthor = cmpI18n?.defaultAuthor
  const metaCopyrightYear = new Date().getFullYear()
  const metaDescription = seoDescription || cmpI18n?.defaultDescription
  const metaFacebookAppId = cmpI18n?.facebookAppId || null
  const metaImage = seoImage || cmpI18n?.defaultImage
  const metaImageAlt = seoImageAlt || cmpI18n?.defaultImageAlt
  const metaLanguage = language || cmpI18n?.defaultLanguage
  const metaLocalVariant = cmpI18n?.defaultLocaleVariant
  const metaOrganization = cmpI18n?.defaultOrganization
  const metaSiteName = cmpI18n?.siteName
  const metaTitle = seoTitle ? seoTitle + ' - ' + cmpI18n?.siteName : cmpI18n?.defaultTitle
  const metaTwitterCreator = cmpI18n?.defaultTwitterAuthor
  const metaTwitterHandle = cmpI18n?.twitterHandle
  const metaUrl = canonicalUrl || window?.location?.href
  const metaRobots = robotsTags || 'all'

  /* Structured Data Configuration */
  const sdSchemaOrg = {
    '@type': 'Organization',
    name: metaOrganization
  }

  const sdSchema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: metaUrl,
    headline: metaTitle,
    inLanguage: metaLanguage,
    mainEntityOfPage: metaUrl,
    description: metaDescription,
    name: metaTitle,
    author: metaAuthor,
    copyrightHolder: sdSchemaOrg,
    copyrightYear: metaCopyrightYear,
    creator: sdSchemaOrg,
    publisher: sdSchemaOrg,
    image: {
      '@type': 'ImageObject',
      url: metaImage
    }
  }

  return (
    <Helmet>
      <html lang={metaLanguage} />
      <meta http-equiv='X-UA-Compatible' content='IE=edge,chrome=1' />
      <meta name='viewport' content='width=device-width,minimum-scale=1,initial-scale=1,shrink-to-fit=no' />

      <link rel='sitemap' type='application/xml' href='/sitemap.xml' />
      <meta name='googlebot' content={metaRobots} />
      <meta name='msnbot' content={metaRobots} />
      <meta name='robots' content={metaRobots} />
      <meta name='slurp' content={metaRobots} />

      <title>{metaTitle}</title>

      <link rel='canonical' href={metaUrl} />

      <meta name='author' content={metaAuthor} />
      <meta name='description' content={metaDescription} />
      <meta name='image' content={metaImage} />

      <meta itemprop='description' content={metaDescription} />
      <meta itemprop='image' content={metaImage} />

      <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png?v=1' />
      <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png?v=1' />
      <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png?v=1' />
      <link rel='manifest' href='/site.webmanifest?v=1' />
      <link rel='mask-icon' href='/safari-pinned-tab.svg?v=1' color='#eb002a' />
      <link rel='shortcut icon' href='/favicon.ico?v=1' />
      <meta name='msapplication-TileColor' content='#ffffff' />
      <meta name='theme-color' content='#ffffff' />

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:creator' content={metaTwitterCreator} />
      <meta name='twitter:description' content={metaDescription} />
      <meta name='twitter:site' content={metaTwitterHandle} />
      <meta name='twitter:title' content={metaTitle} />
      <meta name='twitter:image' content={metaImage} />
      <meta name='twitter:image:secure_url' content={metaImage} />
      <meta name='twitter:image:alt' content={metaImageAlt} />

      <meta property='og:description' content={metaDescription} />
      <meta property='og:image' content={metaImage} />
      <meta property='og:image:secure_url' content={metaImage} />
      <meta property='og:image:alt' content={metaImageAlt} />
      <meta property='og:locale' content={metaLocalVariant} />
      <meta property='og:site_name' content={metaSiteName} />
      <meta property='og:title' content={metaTitle} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={metaUrl} />
      <meta name='fb:app_id' content={metaFacebookAppId} />

      <script type='application/ld+json'>{JSON.stringify(sdSchema)}</script>
    </Helmet>
  )
}
