import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './NavigationMainSmallMenu.module.scss'
import * as Framework from 'components/framework'

/**
 * The Navigation - Main - Small - Menu Component is used to display navigation content on mobile devices
 */
const NavigationMainSmallMenu = ({
  addtOnClick,
  analytics,
  i18n,
  items,
  link
}) => {
  // const [activeMenu, setActiveMenu] = useState(null)
  // useEffect(() => {
  //   if (items) {
  //     let index = items?.findIndex(item => item?.active || item?.current)
  //     if (index !== -1)
  //       setActiveMenu(index)
  //   }
  // }, [items]);

  const cmpTheme = {
    linkBack: {
      style: {
        color: 'text-color--inherit',
        font: 'font--gotham-book',
        size: 'text-size--small'
      },
      type: 'button'
    },
    linkL1: {
      style: {
        color: 'text-color--inherit',
        font: 'font--gotham-book',
        size: 'headline-text-size--x-small'
      },
      type: 'button'
    },
    linkL2: {
      style: 'textNavL2',
      type: 'link'
    },
    linkL3: {
      style: 'textNavL3',
      type: 'link'
    },
    linkOverview: {
      style: 'none',
      type: 'wrapper'
    },
    linkOverviewText: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--small'
    },
    linkOverviewHeader: {
      color: 'text-color--inherit',
      font: 'font--gotham-bold',
      size: 'text-size--small'
    },
    menuLink: {
      style: 'textMainNavLinkMobile',
      type: 'link'
    }
  }

  const [activeSection, setActiveSection] = useState(null)
  const sectionRefs = [
    { section: useRef() },
    { section: useRef() },
    { section: useRef() },
    { section: useRef() }
  ]

  const closeSection = () => {
    setActiveSection(null)
  }

  const openSection = (path) => {
    if (activeSection !== path) setActiveSection(path)
    // if (activeMenu !== thisActiveMenu) {
    //   setActiveMenu(thisActiveMenu);
    // }
  }

  const isActive = (path) => {
    if (activeSection !== null && activeSection?.indexOf(path) !== -1) return true
    return false
  }



  return (
    <>
      {items && <div className={`${styles['menu-wrapper']} ${(activeSection === null) ? styles.isActive : ''}`}>
        <ul className={`${styles.menu}`}>
          {items.map((item, index) => (
            <li key={index} className={styles['menu-section-link']}>
              <Framework.Button
                addtClassName={(item?.current || item?.active ? styles.highlight : '')}
                analytics={{
                  action: 'Open Section',
                  component: analytics?.component,
                  context: analytics?.context
                }}
                content={item?.title}
                endIcon='chevronRightSmall'
                onClick={() => openSection(item?.path)}
                theme={cmpTheme?.linkL1}
              />
            </li>
          ))}
          {link?.href && <li className={styles['menu-section-link']}>
            <Framework.Anchor
              addtOnClick={addtOnClick}
              analytics={{
                component: analytics?.component,
                context: analytics?.context
              }}
              endIcon={link?.endIcon || (link?.target === '_blank') ? 'openInNewTabSmall' : 'chevronRightSmall'}
              href={link?.href}
              label={link?.label}
              startIcon={link?.startIcon}
              target={link?.target}
              theme={cmpTheme?.menuLink}
            />
          </li>}
        </ul>
      </div>}
      {items && <div className={`${styles['sections-wrapper']} ${(activeSection !== null) ? styles.isActive : ''}`}>
        {items.map((item, index) => (
          <div
            key={index}
            className={`${styles.section} ${isActive(item?.path) ? styles.isActive : ''}`}
            ref={sectionRefs[index]?.section}
          //style={{ maxHeight: isActive(item?.path) ? sectionRefs[index]?.section?.current?.clientHeight + 'px' : 0 }}
          >
            <Framework.Button
              tabIndex='-1'
              content={i18n?.mainMenuText}
              onClick={() => closeSection()}
              startIcon='chevronLeftSmall'
              theme={cmpTheme?.linkBack}
            />
            <div className={styles['section-content']}>
              <ul className={styles['section-list']}>
                <li className={styles['section-overview']}>
                  <Framework.Anchor
                    addtOnClick={addtOnClick}
                    analytics={{
                      component: analytics?.component,
                      context: analytics?.context
                    }}
                    href={item?.path}
                    label={`${item?.title} ${i18n?.overviewText}`}
                    tabIndex='-1'
                    theme={cmpTheme?.linkOverview}
                  >
                    <Framework.Text
                      content={item.title}
                      theme={cmpTheme?.linkOverviewHeader}
                    />
                    <Framework.Text
                      content={i18n.overviewText}
                      theme={cmpTheme?.linkOverviewText}
                    />
                    <Framework.Icon icon='arrowRightSmall' />
                  </Framework.Anchor>
                </li>
                {item?.children.map((sectionItem, index) => (
                  !sectionItem?.hideMainNav && <React.Fragment key={index}>
                    <li className={styles['section-list-header']}>
                      <Framework.Anchor
                        addtOnClick={addtOnClick}
                        addtClassName={(sectionItem?.current || sectionItem?.active ? styles.highlight : '')}
                        analytics={{
                          component: analytics?.component,
                          context: analytics?.context
                        }}
                        endIcon='chevronRightSmall'
                        href={sectionItem?.path}
                        label={`${sectionItem?.title}`}
                        tabIndex='-1'
                        theme={cmpTheme?.linkL2}
                      />
                    </li>
                    {!sectionItem?.hideMainNav && sectionItem?.children.map((sectionL3Item, index) => (
                      !sectionL3Item.hideMainNav && <React.Fragment key={index}>
                        <li className={styles['section-list-link']}>
                          <Framework.Anchor
                            addtOnClick={addtOnClick}
                            addtClassName={(sectionL3Item?.current || sectionL3Item?.active ? styles.highlight : '')}
                            analytics={{
                              component: analytics?.component,
                              context: analytics?.context
                            }}
                            endIcon={sectionL3Item?.blankTarget ? 'openInNewTabSmall' : 'chevronRightSmall'}
                            href={sectionL3Item?.path}
                            label={sectionL3Item?.title}
                            tabIndex='-1'
                            theme={cmpTheme?.linkL3}
                            target={sectionL3Item?.blankTarget ? '_blank' : null}
                          />
                        </li>
                        {sectionL3Item?.children.map((sectionL4Item, index) => (
                          !sectionL4Item.hideMainNav && <React.Fragment key={index}>
                            <li className={styles['section-list-link-l4']}>
                              <Framework.Anchor
                                addtOnClick={addtOnClick}
                                addtClassName={(sectionL4Item?.current || sectionL4Item?.active ? styles.highlight : '')}
                                analytics={{
                                  component: analytics?.component,
                                  context: analytics?.context
                                }}
                                endIcon={sectionL4Item?.blankTarget ? 'openInNewTabSmall' : 'chevronRightSmall'}
                                href={sectionL4Item?.path}
                                label={sectionL4Item?.title}
                                tabIndex='-1'
                                theme={cmpTheme?.linkL3}
                                target={sectionL4Item?.blankTarget ? '_blank' : null}
                              />
                            </li>
                            {sectionL4Item?.children.map((sectionL5Item, index) => (
                              !sectionL5Item.hideMainNav && <li key={index} className={styles['section-list-link-l5']}>
                                <Framework.Anchor
                                  addtOnClick={addtOnClick}
                                  addtClassName={(sectionL5Item?.current || sectionL5Item?.active ? styles.highlight : '')}
                                  analytics={{
                                    component: analytics?.component,
                                    context: analytics?.context
                                  }}
                                  endIcon={sectionL5Item?.blankTarget ? 'openInNewTabSmall' : 'chevronRightSmall'}
                                  href={sectionL5Item?.path}
                                  label={sectionL5Item?.title}
                                  tabIndex='-1'
                                  theme={cmpTheme?.linkL3}
                                  target={sectionL5Item?.blankTarget ? '_blank' : null}
                                />
                              </li>
                            ))}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>}
    </>
  )
}

NavigationMainSmallMenu.propTypes = {
  /**
   * Additional On Click function
   */
  addtOnClick: PropTypes.func,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    component: PropTypes.string,
    context: PropTypes.string
  }),
  /**
   * i18n - Translation copy
   */
  i18n: PropTypes.object,
  /**
   * Items to render
   */
  items: PropTypes.array,
  /**
   * Link - Manual Link information
   */
  link: PropTypes.object
}

export default NavigationMainSmallMenu
