export default <svg
  width={20}
  height={20.45}
  viewBox="0 0 20 20.45"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M11.903 8.655 19.348 0h-1.764L11.119 7.515 5.955 0H0l7.808 11.364L0 20.439h1.764l6.827 -7.936 5.453 7.936H20L11.902 8.655zM9.486 11.464l-0.791 -1.132 -6.295 -9.004h2.71l5.08 7.267 0.791 1.132 6.603 9.445H14.875L9.486 11.464z"
    fill="white"
  />
</svg>
