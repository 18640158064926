import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styles from './Button.module.scss'
import { Icon } from 'components/framework'
import Typography from 'components/scaffolding/Typography'
import { trackInteraction } from 'utils/analytics'

/**
 * The Button Component is used as...
 */
const Button = ({
  addtClassName,
  analytics,
  content,
  endIcon,
  label,
  onClick,
  startIcon,
  theme,
  ...props
}) => {

  // Handle button click
  const handleClick = useCallback(() => {
    trackInteraction({
      action: analytics?.action,
      component: analytics?.component,
      context: analytics?.context,
      label: label
    })
    onClick()
  }, [analytics, label, onClick])

  return (
    <button
      aria-label={label}
      className={
        styles.base +
        (addtClassName ? ' ' + addtClassName : '')
      }
      onClick={onClick ? handleClick : null}
      type='button'
      {...props}
    >
      {(() => {
        switch (theme?.type) {
          case 'wrapper':
            return content
          case 'button':
            return (
              <>
                {startIcon && <Icon
                  icon={startIcon}
                  size={24}
                />}
                <Typography
                  color={theme?.style?.color}
                  font={theme?.style?.font}
                  size={theme?.style?.size}
                >
                  {content}
                </Typography>
                {endIcon && <Icon
                  icon={endIcon}
                  size={24}
                />}
              </>
            )
          default:
        }
      })()}
    </button>
  )
}

Button.propTypes = {
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    action: PropTypes.string,
    component: PropTypes.string,
    context: PropTypes.string,
    label: PropTypes.string
  }),
  /**
   * Content, accepts text labels or SVGs
   */
  content: PropTypes.any,
  /**
  * End Icon - Optional icon to display after the label
  */
  endIcon: PropTypes.string,
  /**
   * Button label, accepts text
   */
  label: PropTypes.string,
  /**
   * Click handler - Optional function
   */
  onClick: PropTypes.func,
  /**
   * Start Icon - Optional icon to display before the label
   */
  startIcon: PropTypes.string,
  /**
   * Design theme
   * - style: primary, secondary, custom
   * - type: button, wrapper
   */
  theme: PropTypes.shape({
    style: PropTypes.shape({
      color: PropTypes.string,
      font: PropTypes.string,
      size: PropTypes.string
    }),
    type: PropTypes.string
  })
}

Button.defaultProps = {
  content: 'Missing content',
  theme: {
    style: {
      color: 'text-color--inherit',
      font: 'font--gotham-medium',
      size: 'text-size--cta'
    },
    type: 'button'
  }
}

export default Button
