(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof exports === 'object')
		exports["@adobe/aem-core-components-react-spa"] = factory();
	else
		root["@adobe/aem-core-components-react-spa"] = factory();
})(typeof self !== 'undefined' ? self : this, function() {
return 