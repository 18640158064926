import { MapTo } from '@adobe/aem-react-editable-components'
import withAsyncImport from 'utils/withAsyncImport'

const NavigationMainV2 = withAsyncImport(() =>
  import('./NavigationMainV2')
)

const NavigationMainV2EditableConfig = {
  emptyLabel: 'Navigation Main V2',

  isEmpty: function (props) {
    return !props || !props.configured
  }
}

export default MapTo('aramark/components/content/navigation-main-v2')(NavigationMainV2, NavigationMainV2EditableConfig)

