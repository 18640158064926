import { MapTo } from '@adobe/aem-react-editable-components'
import withAsyncImport from 'utils/withAsyncImport'

const BannerFullWidth = withAsyncImport(() =>
  import('./BannerFullWidth')
)

const BannerFullWidthEditableConfig = {
  emptyLabel: 'Banner - Full Width',

  isEmpty: function (props) {
    return !props || !props.configured
  }
}

export default MapTo('aramark/components/content/banner-full-width')(BannerFullWidth, BannerFullWidthEditableConfig)
