import React from 'react'
import PropTypes from 'prop-types'
import extractModelId from 'utils/extract-model-id'
import NavigationMainSmall from './Small'
import NavigationMainLarge from './Large'
import { useWindowSize } from 'hooks/useWindowSize'

/**
 * The Navigation - Main Component is used to determine whether the small or large version of the nav is rendered
 */
const NavigationMain = ({
  cmpI18n,
  disableSticky,
  items,
  link,
  scrollPosition,
  utilityNav,
  rootPath,
  ...props
}) => {
  const { width } = useWindowSize()
  const navRootPath = rootPath;

  const cmpAnalytics = {
    component: props?.cqType
  }

  return (
    <nav
      data-component='Navigation - Main'
      id={extractModelId(props?.cqPath)}
      title={props?.title}
    >
      {width > 1160 ? (
        <NavigationMainLarge
          analytics={cmpAnalytics}
          disableSticky={disableSticky}
          i18n={cmpI18n}
          items={items}
          link={link}
          scrollPosition={scrollPosition}
          navigationRootPath={navRootPath}
        />
      ) : (
        <NavigationMainSmall
          analytics={cmpAnalytics}
          disableSticky={disableSticky}
          i18n={cmpI18n}
          items={items}
          link={link}
          scrollPosition={scrollPosition}
          utilityNav={utilityNav}
        />
      )}
    </nav>
  )
}

NavigationMain.propTypes = {
  /**
   * Internationalization values from dictionary
   */
  cmpI18n: PropTypes.object,
  /**
   * Disabled Sticky mode
   */
  disableSticky: PropTypes.bool,
  /**
   * Items to render
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          active: PropTypes.bool,
          children: PropTypes.array,
          current: PropTypes.bool,
          level: PropTypes.number,
          path: PropTypes.string,
          title: PropTypes.string
        })
      ),
      current: PropTypes.bool,
      level: PropTypes.number,
      path: PropTypes.string,
      title: PropTypes.string
    })
  ),
  /**
   * Manual Link (Call To Action)
   */
  link: PropTypes.shape({
    endIcon: PropTypes.string,
    href: PropTypes.string,
    label: PropTypes.string,
    startIcon: PropTypes.string,
    target: PropTypes.string
  }),
  /**
   * Scroll Position pass through
   */
  scrollPosition: PropTypes.number,
  /**
   * Utility Nav content payload for embedded version on mobile
   */
  utilityNav: PropTypes.object
}

export default NavigationMain