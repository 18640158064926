import sanitizeHtml from 'sanitize-html'
import sanitizeWhiteList from '../../sanitize-html.whitelist'

import React from 'react'
import PropTypes from 'prop-types'
import styles from './Title.module.scss'
import Typography from 'components/scaffolding/Typography'

// Get semantic tag from level parameter
const levelLookUp = ['h2', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']

/**
 * The Title Component is used as...
 */
const Title = ({
  addtClassName,
  content,
  level,
  theme,
  ...props
}) => {
  const Tag = levelLookUp[level] || levelLookUp[0]

  return (
    <Tag
      className={addtClassName ? styles.title + ' ' + addtClassName : styles.title}
      {...props}
    >
      <Typography
        color={theme?.color}
        font={theme?.font}
        linesToDisplay={theme?.linesToDisplay}
        size={theme?.size}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(content, {
            sanitizeWhiteList,
            // Automatically convert ® and © symbols to superscript
            textFilter: function(text) {
              return text
                .replace('®', `<sup class="${styles.sup}">&reg</sup>`)
                .replace('©', `<sup class="${styles.sup}">&copy</sup>`)
            },
            transformTags: {
              'sup': sanitizeHtml.simpleTransform('sup', { class: styles.sup })
            }
          })
        }}
      />
    </Tag>
  )
}

Title.propTypes = {
  /**
   * Additional Class Name - passed from the parent Component
   * -- allows box model adjustments (margin, padding, position styles etc)
   * -- should not be used for typography or color adjustments (font family, font weight, text color etc)
   */
  addtClassName: PropTypes.string,
  /**
   * Content
   */
  content: PropTypes.string,
  /**
   * Level
   */
  level: PropTypes.string,
  /**
   * Theme
   */
  theme: PropTypes.shape({
    color: PropTypes.string,
    font: PropTypes.string,
    linesToDisplay: PropTypes.number,
    size: PropTypes.string
  })
}

Title.defaultProps = {
  content: 'Missing content text',
  level: '0',
  theme: {
    color: 'text-color--black',
    font: 'font--reckless-neue',
    size: 'headline-text-size--x-large'
  }
}

export default Title
