import { Container, MapTo } from '@adobe/aem-react-editable-components';
import withAsyncImport from 'utils/withAsyncImport'

import { withAuthorPanelSwitch } from '@adobe/aem-core-components-react-spa';

const Accordion = withAsyncImport(() =>
  import('./Accordion')
)

const AccordionEditableConfig = {
    emptyLabel: 'Accordion',

    isEmpty: function (props) {
        return !props || !props.configured;
    },
};

export class AccordionContainer extends Container {
  /**
   * Returns the properties needed on the root element
   * so that the AEM Panel editor is enabled
   */
  get accordionContainerProps() {
    const attrs = this.containerProps;
    attrs['className'] = 'cmp-accordion';
    attrs['data-cmp-is'] = 'accordion';
    attrs['data-panelcontainer'] = 'accordion';

    return attrs;
  }

  render() {
    const {
      activeIndexFromAuthorPanel,
      expandedItems,
      isInEditor,
      singleExpansion,
    } = this.props;

    return (
      <div {...this.accordionContainerProps}>
        <Accordion
          editModeActiveIndex={activeIndexFromAuthorPanel}
          isInEditor={isInEditor}
          placeholder={this.props.placeholder}
          childComponents={this.childComponents}
          expandedItems={expandedItems}
          placeholderComponent={this.placeholderComponent}
          singleExpansion={singleExpansion}
          {...this.props}
        />
      </div>
    )
  }
}

export default MapTo('aramark/components/content/accordion')(
  withAuthorPanelSwitch(AccordionContainer),
  AccordionEditableConfig,
)
