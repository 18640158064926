import React from 'react'
import PropTypes from 'prop-types'
import styles from './Columns.module.scss'
import extractModelId from 'utils/extract-model-id'
import { generateStyleModifiers } from 'utils/style-modifiers'

/**
 * The Columns Component is used to display content in columns
 */
const Columns = ({
  columnsCount,
  ...props
}) => {

  const cmpThemeModifiers = generateStyleModifiers(props?.appliedCssClassNames, true)

  const cmpTheme = {
    spacing: {
      above: cmpThemeModifiers?.spacingAbove || 'inherit',
      below: cmpThemeModifiers?.spacingBelow || 'inherit'
    }
  }

  return (
    <div
      className={`${styles.columns} ${styles['columns-' + columnsCount]} osa-${cmpTheme?.spacing?.above} osb-${cmpTheme?.spacing?.below}`}
      id={extractModelId(props?.cqPath)}
      title={props?.title}
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>

          {(columnsCount > 0) && props?.children[0] && <div className={styles['column-1']}>
            {props?.children[0]}
          </div>}

          {(columnsCount > 1) && props?.children[1] && <div className={styles['column-2']}>
            {props?.children[1]}
          </div>}

          {(columnsCount > 2) && props?.children[2] && <div className={styles['column-3']}>
            {props?.children[2]}
          </div>}

        </div>
      </div>
    </div>
  )
}

Columns.propTypes = {
  /**
   * Columns Count - the number of columns to display
   */
  columnsCount: PropTypes.oneOf([
    '2',
    '3'
  ])
}

Columns.defaultProps = {
  columnsCount: '2'
}

export default Columns
