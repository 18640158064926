import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { trackPageView } from 'utils/analytics'

// Component that handles page view analytics on route change
const ScrollToTopOnRouteChange = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    trackPageView()
  }, [pathname])
  
  // Renders nothing, since nothing is needed
  return null
}

export default ScrollToTopOnRouteChange
