import { MapTo } from '@adobe/aem-react-editable-components'
import withAsyncImport from 'utils/withAsyncImport'

const DynamicFeed = withAsyncImport(() =>
  import('./DynamicFeed')
)

const DynamicFeedEditableConfig = {
  emptyLabel: 'Dynamic Feed',

  isEmpty: function (props) {
    return !props || !props.configured
  }
}

export default MapTo('aramark/components/content/dynamic-feed')(DynamicFeed, DynamicFeedEditableConfig)
