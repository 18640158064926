import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './NavigationMainLargeMenu.module.scss'
import * as Framework from 'components/framework'
import MenuItem from '../MenuItem'

/**
 * The Navigation - Main - Large - Menu Component is used to display
 * navigation content for tablet and desktop devices
 */
const NavigationMainLargeMenu = ({
  activeMenu,
  addtOnClick,
  analytics,
  i18n,
  items,
  openMenuOverlay
}) => {
  const [height, setHeight] = useState(null)

  const cmpTheme = {
    linkFaq: {
      style: 'textMainNavFaqLink',
      type: 'link'
    },
    linkL2: {
      style: 'textNavL2',
      type: 'link'
    },
    linkL3: {
      style: 'textNavL3',
      type: 'link'
    },
    linkOverview: {
      style: 'none',
      type: 'wrapper'
    },
    linkOverviewText: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--navigation'
    },
    linkOverviewHeader: {
      color: 'text-color--inherit',
      font: 'font--gotham-bold',
      size: 'text-size--small'
    }
  }

  const sectionRefs =  [
    { section: useRef(null) },
    { section: useRef(null) },
    { section: useRef(null) },
    { section: useRef(null) },
    { section: useRef(null) }
  ]


  const isActive = (elementIndex) => {
    if (activeMenu === elementIndex) return true
    return false
  }

  useEffect(() => {
    let maxHeight = 0
    sectionRefs.forEach((ref => {
      const sectionEl = ref?.section?.current
      if (sectionEl) {
        const listElements = sectionEl?.querySelectorAll('ul[data-element-type="submenu"]')
        listElements?.forEach(el=>{
          if(el.offsetHeight > maxHeight) {
            maxHeight = el?.offsetHeight
          }
        })
      }
    }))
    setHeight(maxHeight)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height])

  return (
    <>
      {items?.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {item?.children.length > 0 && <div
              className={`${styles.section} ${isActive(index) ? styles.isActive : ''}`}
              ref={sectionRefs[index]?.section}
            >
              <div className={styles['section-content']}>
                <ul className={styles['section-list']}>
                  <li className={styles['section-overview']}>
                    {/* Section overview link */}
                    <Framework.Anchor
                      addtClassName={item?.current ? styles.highlighted : ''}
                      addtOnClick={addtOnClick}
                      analytics={{
                        component: analytics?.component,
                        context: analytics?.context
                      }}
                      href={item?.path}
                      label={`${item?.title} ${i18n?.overviewText}`}
                      theme={cmpTheme?.linkOverview}
                      onFocus={() => openMenuOverlay ? openMenuOverlay(index) : null}
                    >
                      <Framework.Text
                        content={`${item.title} ${i18n.overviewText}`}
                        theme={cmpTheme?.linkOverviewText}
                      />
                      <Framework.Icon icon='arrowRightSmall' />
                    </Framework.Anchor>
                  </li>
                  <li className={styles['section-group-wrapper']} style={{ height: height > 0 ? height: 'auto' }}>
                    <ul className={styles['section-list-group']}>
                      {item?.children.map((sectionItem, sectionIndex) => {
                        if (sectionItem?.path !== item?.path) {
                          // Section links
                          return (
                            <MenuItem 
                              analytics={analytics}
                              item={sectionItem}
                              itemIndex={index}
                              key={sectionIndex}
                              cmpTheme={cmpTheme}
                              openMenuOverlay={openMenuOverlay}
                            />
                          )
                        }
                        return null
                      })}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>}
          </React.Fragment>
        )
      })}
    </>
  )
}

NavigationMainLargeMenu.propTypes = {
  /**
   * Additional On Click function
   */
  addtOnClick: PropTypes.func,
  /**
   * Active Menu - reference to the active menu
   */
  activeMenu: PropTypes.number,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    component: PropTypes.string,
    context: PropTypes.string
  }),
  /**
   * i18n - Translation copy
   */
  i18n: PropTypes.object,
  /**
   * Items to render
   */
  items: PropTypes.array,
  /**
   * Open menu callback
   */
  openMenuOverlay: PropTypes.func
}

export default NavigationMainLargeMenu
