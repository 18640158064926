const serviceI18n = {
  errorMessage: 'Unable to submit form. Please try again...'
}

// Post a form to Salesforce
const postToSalesforce = async (url, payload) => {
  let salesforceObj = []
  for (let property in payload) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(payload[property])
    salesforceObj.push(encodedKey + "=" + encodedValue)
  }
  salesforceObj = salesforceObj.join('&')

  const response = await fetch(url, {
    method: 'POST',
    mode: 'no-cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: salesforceObj
  })

  if (response.status !== 0) {
    return handleError(response)
  }
  return handleSuccess(response)
}

const handleError = (response) => {
  return {
    message: serviceI18n?.errorMessage,
    response: response,
    success: false
  }
}

const handleSuccess = (response) => {
  return {
    response: response,
    success: true
  }
}

export {
  postToSalesforce
}
