//import React, { useRef } from 'react'
import MenuItem from '../MenuItem'
import PropTypes from 'prop-types'
import styles from './NavigationMainLargeSubMenu.module.scss'

/**
 * The Navigation - Main - Large - Menu Component is used to display navigation content for tablet and desktop devices
 */
const NavigationMainLargeSubMenu = ({
  addtOnClick,
  analytics,
  cmpTheme,
  item,
}) => {

  return (
    <ul className={styles['section-list-submenu']}  data-element-type='submenu'>
      {item?.children.map((child, index) => {
        if (item?.path !== child?.path && item?.level <= child?.level) {
          return (
            <MenuItem 
              item={child}
              key={index}
              addtOnClick={addtOnClick}
              analytics={analytics}
              cmpTheme={cmpTheme}
            />
          )
        }
        return null
      })}
    </ul>
  )
}

NavigationMainLargeSubMenu.propTypes = {
  /**
   * Additional On Click function
   */
  addtOnClick: PropTypes.func,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    component: PropTypes.string,
    context: PropTypes.string
  }),
  /**
   * cmpTheme 
   */
  cmpTheme: PropTypes.object,
  /**
   * Parent menu item
   */
  item: PropTypes.object
}

export default NavigationMainLargeSubMenu
