import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './Checkbox.module.scss'
import * as Framework from 'components/framework'
import DeselectorEmailCaptureConfig from "../../../content/Form/Templates/DeselectorEmailCapture/DeselectorEmailCaptureConfig";
import Form from "../../Form/Form";
import DeselectorEmailCapture from "../../../content/DeselectorEmailCapture/DeselectorEmailCapture";

/**
 * The Radio Component is used as...
 */
const Checkbox = React.forwardRef(({
  addtClassName,
  analytics,
  checked,
  disabled,
  id,
  label,
  name,
  onChange,
  theme,
  value,
  ...props
}, ref) => {

  const cmpTheme = {
    label: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--large'
    }
  }

  const config = DeselectorEmailCaptureConfig(props);
  const [isChecked, setIsChecked] = useState(false);
  let defaultCheckedValues = config.form.fields.filter(item => item.params && item.params.defaultChecked !== undefined)[0].params.defaultChecked
  sessionStorage.setItem("defaultCheckedValues", defaultCheckedValues);
  useEffect(()=>{
    setIsChecked(defaultCheckedValues);
  }, []);

  const onConsentChange = (event) => {
    setIsChecked(event.target.checked);
  }

  return (
    <>
    <label
      className={styles[theme] + (addtClassName ? ' ' + addtClassName : '')}
      id={`${id}-wrapper`}
    >
      <input
        aria-disabled={disabled}
        checked={name === "deselectorCheckbox" ? isChecked : checked}
        disabled={disabled}
        id={`${id}-input`}
        name={name}
        onChange={name === "deselectorCheckbox" ? onConsentChange : onChange}
        ref={ref}
        type='checkbox'
        value={value}
        {...props}
      />
      {label && (
        <Framework.Text
          className={
            styles.label +
            (checked ? ' ' + styles['label-selected'] : '')
          }
          content={label}
          id={`${id}-label`}
          inline
          theme={cmpTheme.label}
        />
      )}
    </label>
    </>
  )
})

Checkbox.propTypes = {
  /**
   * Additional Class Name - passed from the parent Component
   * -- allows box model adjustments (margin, padding, position styles etc)
   * -- should not be used for typography or color adjustments (font family, font weight, text color etc)
   */
   addtClassName: PropTypes.string,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    action: PropTypes.string,
    component: PropTypes.string,
    context: PropTypes.string,
    label: PropTypes.string
  }),
  /**
   * Checked state of the radio button
   */
  checked: PropTypes.bool,
  /**
   * Disabled - disables the input
   */
  disabled: PropTypes.bool,
  /**
   * ID - unique identifier for the textfield
   */
  id: PropTypes.string,
  /**
   * Select label
   */
  label: PropTypes.string,
  /**
   * Name of the input
   */
  name: PropTypes.string,
  /**
   * Optional change handler
   */
  onChange: PropTypes.func,
  /**
   * Design theme
   */
  theme: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  /**
   * Value of the input
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

Checkbox.defaultProps = {
  analytics: {
    action: '<Purpose of the interaction>',
    component: '<Parent component container identifier>',
    context: '<Additional information to add context to avoid ambiguity>',
    label: '<Text displayed within the button element>'
  },
  theme: 'primary'
}

Checkbox.name = 'FormElement.checkbox'
export default Checkbox
