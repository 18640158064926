import { MapTo } from '@adobe/aem-react-editable-components'
import withAsyncImport from 'utils/withAsyncImport'

const Asset5050 = withAsyncImport(() =>
  import('./Asset5050')
)

const Asset5050EditableConfig = {
  emptyLabel: '50/50 Text with Image or Video',

  isEmpty: function (props) {
    return !props || !props.configured
  }
}

export default MapTo('aramark/components/content/asset-5050')(Asset5050, Asset5050EditableConfig)
