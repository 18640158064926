import { MapTo } from '@adobe/aem-react-editable-components'
import NavigationMain from './NavigationMain'

const NavigationMainEditableConfig = {
  emptyLabel: 'Navigation - Main',

  isEmpty: function (props) {
    return !props || !props.configured
  }
}

export default MapTo('aramark/components/content/navigation-main')(NavigationMain, NavigationMainEditableConfig)
