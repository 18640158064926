import React, { useState} from 'react'
import PropTypes from 'prop-types'
import styles from './NavigationMainLargeMenuItem.module.scss'
import * as Framework from 'components/framework'
import SubMenu from '../SubMenu'

/**
 * The Navigation - Main - Large - Menu Component is used to display navigation content for tablet and desktop devices
 */
const NavigationMainLargeMenuItem = ({
  addtOnClick,
  analytics,
  item,
  cmpTheme,
  itemIndex,
  openMenuOverlay
}) => {
  const [isActive, setIsActive] = useState(false)
  const [delayHandler, setDelayHandler] = useState(null)

  const handleMouseEnter = () => {
    setIsActive(true)
    if (delayHandler) {
      clearTimeout(delayHandler)
    }
  }

  const handleMouseLeave = () => {
    setDelayHandler(setTimeout(() => setIsActive(false), 200))
  }

  // Dont't render endIcon for empty submenus
  const checkEndIcon = (item) => {
    if (item.children?.length === 1 && item.children[0]?.path === item.path) {
      return null
    }
    return 'chevronRightSmall'
  }

  return (
    <>
      {/* Collapsible menu */}
      {!item.hideMainNav && item?.children?.length > 0 ? (
        <li
          className={
            styles['section-list-header'] + ' ' +
            (isActive ? styles.isHovered : '')
          }
          onMouseEnter={() => handleMouseEnter()}
          onMouseLeave={() => handleMouseLeave()}
          onFocus={() => handleMouseEnter()}
          onBlur={() => handleMouseLeave()}
        >
          <Framework.Anchor
            addtClassName={(item?.current || item?.active ? styles.highlighted : '')}
            analytics={{
              component: analytics?.component,
              context: item?.title,
            }}
            endIcon={checkEndIcon(item)}
            label={item?.title}
            href={item?.path}
            target={item?.windowOpen ? '_blank' : null}
            addtOnClick={addtOnClick}
            theme={cmpTheme?.linkL3}
            onFocus={() => openMenuOverlay ? openMenuOverlay(itemIndex) : null}
          />
          <SubMenu 
            addtOnClick={addtOnClick}
            analytics={analytics}
            cmpTheme={cmpTheme}
            isActive={isActive}
            item={item}
          /> 
        </li>
      ) : (
        <li
          className={
            styles['section-list-link'] + ' ' +
            (isActive ? styles.isHovered : '')
          }
        >
          {/* Standard link */}
          <Framework.Anchor
            addtClassName={(item?.current || item?.active ? styles.highlighted : '')}
            analytics={{
              component: analytics?.component,
              context: item?.title,
            }}
            endIcon={null}
            label={item?.title}
            href={item?.path}
            target={item?.windowOpen ? '_blank' : null}
            addtOnClick={addtOnClick}
            theme={cmpTheme?.linkL3}
          />
        </li>
      )}
    </>
  )
}

NavigationMainLargeMenuItem.propTypes = {
  /**
   * Additional On Click function
   */
  addtOnClick: PropTypes.func,
  /**
   * Active Menu - reference to the active menu
   */
  activeMenu: PropTypes.number,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    component: PropTypes.string,
    context: PropTypes.string
  }),
    /**
   * cmpTheme 
   */
     cmpTheme: PropTypes.object,
  /**
   * Item to render
   */
  item: PropTypes.object,
  /**
   * Item index
   */
  itemIndex: PropTypes.number,
  /**
   * Open menu callback
   */
  openMenuOverlay: PropTypes.func
}

export default NavigationMainLargeMenuItem
