import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './SubNavigation.module.scss'
import * as Framework from 'components/framework'

/**
 * The Navigation - Sub Navigation Component is used to render a sub navigation
 */
const SubNavigation = ({
  analytics,
  displaySubnav,
  links,
  ...props
}) => {

  const cmpAnalytics = {
    component: props?.cqType
  }

  const cmpTheme = {
    link: {
      style: 'subNavigation',
      type: 'link'
    },
    linkSelected: {
      style: 'subNavigationSelected',
      type: 'link'
    }
  }

  const [subNavLinks, setSubNavLinks] = useState([])

  useEffect(() => {
    const _filterCurrent = (arr) => {
      return arr?.filter((link) => { return link?.current })
    }
    const _filterActive = (arr) => {
      return arr?.filter((link) => { return link?.active })
    }
    const _getModelChildren = (obj) => {
      return (obj?.length > 0 ) ? obj[0].children : []
    }

    let tmpSubNavLinks = []
  
    // L1 - shows L2 child pages, displays a back to L1 link
    const currentL1Section = _filterCurrent(links)
    const activeL1Section = _filterActive(links)

    if (currentL1Section?.length > 0) {
      tmpSubNavLinks = _getModelChildren(currentL1Section)
      if (tmpSubNavLinks[0]?.path !== activeL1Section[0]?.path) {
        const newActiveL1Section = {
          ...activeL1Section[0],
          children: []
        }
        tmpSubNavLinks.unshift(newActiveL1Section)
      }
    } else {
      // L2 - shows L2 sibling pages and not children, displays a back to L1 link
      const navModelL2 = _getModelChildren(activeL1Section)
      const currentL2Section = _filterCurrent(navModelL2)
      const activeL2Section = _filterActive(navModelL2)

      if (currentL2Section?.length > 0) {
        tmpSubNavLinks = navModelL2

        if (tmpSubNavLinks[0]?.path !== activeL1Section[0]?.path) {
          const newActiveL1Section = {
            ...activeL1Section[0],
            children: []
          }
          tmpSubNavLinks.unshift(newActiveL1Section)
        }
      } else {
        // L3 - shows L4 child pages, displays a back to L3 link
        const navModelL3 = _getModelChildren(activeL2Section)
        const currentL3Section = _filterCurrent(navModelL3)
        const activeL3Section = _filterActive(navModelL3)

        if (currentL3Section?.length > 0) {
          tmpSubNavLinks = _getModelChildren(currentL3Section)

          if (tmpSubNavLinks[0]?.path !== activeL3Section[0]?.path) {
            const newActiveL3Section = {
              ...activeL3Section[0],
              children: []
            }
            tmpSubNavLinks.unshift(newActiveL3Section)
          }
        } else {
          // L4 - shows L4 sibling pages and not children, displays a back to L3 link
          const navModelL4 = _getModelChildren(activeL3Section)
          const currentL4Section = _filterCurrent(navModelL4)
          const activeL4Section = _filterActive(navModelL4)

          if (currentL4Section?.length > 0) {
            tmpSubNavLinks = navModelL4

            if (tmpSubNavLinks[0]?.path !== activeL3Section[0]?.path) {
              const newActiveL3Section = {
                ...activeL3Section[0],
                children: []
              }
              tmpSubNavLinks.unshift(newActiveL3Section)
            }
          } else {
            // L5 - shows L5 sibling pages and not children, displays a back to L3 link
            const navModelL5 = _getModelChildren(activeL4Section)
            const currentL5Section = _filterCurrent(navModelL5)

            if (currentL5Section?.length > 0) {
              tmpSubNavLinks = navModelL5

              if (tmpSubNavLinks[0]?.path !== activeL3Section[0]?.path) {
                const newActiveL3Section = {
                  ...activeL3Section[0],
                  children: []
                }
                tmpSubNavLinks.unshift(newActiveL3Section)
              }
            }
          }
        }
      }
    }
    setSubNavLinks(tmpSubNavLinks)
  }, [links])

  return (
    <>
      {displaySubnav && subNavLinks?.length > 0 && <ul
        className={styles.subnav}
        data-component='Navigation - Sub Navigation'
      >
        {subNavLinks?.map((link, index) => (
          <li
            className={(link?.current === true) ? styles.selected : ''}
            key={index}
          >
            <Framework.Anchor
              analytics={{
                component: cmpAnalytics?.component
              }}
              href={link?.path}
              label={link?.title}
              target={link?.windowOpen ? '_blank' : '_self'}
              theme={(link?.current === true) ? cmpTheme?.linkSelected : cmpTheme?.link}
            />
          </li>
        ))}
      </ul>}
    </>
  )
}

SubNavigation.propTypes = {
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    component: PropTypes.string,
    context: PropTypes.string
  }),
  /**
   * Display Sub Nav
   */
  displaySubnav: PropTypes.bool,
  /**
   * Links
   */
  links: PropTypes.array
}
SubNavigation.defaultProps = {}

export default SubNavigation
