import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styles from './RadioGroup.module.scss'
import * as Framework from 'components/framework'

import Radio from '../Radio'

const RadioGroup = React.forwardRef(({
  addtClassName,
  analytics,
  disabled,
  error,
  id,
  isSubmitted,
  isValid,
  label,
  name,
  options,
  required,
  setValue,
  theme,
  value,
  ...props
}, ref) => {
  const [selected, setSelected] = useState(value)

  const cmpTheme = {
    label: {
      color: 'text-color--inherit',
      font: 'font--gotham-book',
      size: 'text-size--small'
    },
    error: {
      color: 'text-color--red',
      font: 'font--gotham-book',
      size: 'text-size--large'
    }
  }

  // Pass current selection to setValue callback
  useEffect(() => {
    if (setValue) setValue(selected)
  }, [selected, setValue])

  // Handle checkbox selection
  const handleSelect = (selection) => {
    setSelected(selection)
  }

  return (
    <div
      className={
        styles[theme] +
        (addtClassName ? ' ' + addtClassName : '')
      }
      id={`${id}-container`}
      // Additional props are added directly to fieldset container
      {...props}
    >
      <fieldset
        className={
          styles.wrapper +
          (isSubmitted && isValid ? ' ' + styles['wrapper-success'] : '') +
          (error ? ' ' + styles['wrapper-error'] : '')
        }
        id={`${id}-wrapper`}
      >
        {label && (
          <Framework.Text
            className={
              styles.label +
              (isSubmitted && isValid ? ' ' + styles['label-success'] : '') +
              (error ? ' ' + styles['label-error'] : '')
            }
            content={required ? '*' + label : label}
            id={`${id}-label`}
            inline
            theme={cmpTheme.label}
          />
        )}
        {options?.map((option, index) => (
          <Radio
            // If an error is present and the input has a unique id, the aria-describedby attribute is added
            aria-describedby={error && id ? `${id}-error` : null}
            aria-disabled={disabled}
            aria-invalid={Boolean(error)}
            aria-label={option?.label}
            checked={option?.value === selected}
            disabled={disabled}
            id={`${id}-${index}`}
            key={`${id}-${index}`}
            label={option?.label}
            name={name}
            onChange={() => handleSelect(option?.value)}
            ref={ref}
            required={required}
            value={option?.value}
          />
        ))}
      </fieldset>
      {error && (
        <Framework.Text
          className={styles['error']}
          content={error}
          // If the input has a unique id, generate one for the error message as well
          id={`${id}-error`}
          inline
          role='alert'
          theme={cmpTheme.error}
        />
      )}
    </div>
  )
})

RadioGroup.propTypes = {
  /**
   * Additional Class Name - passed from the parent Component
   * -- allows box model adjustments (margin, padding, position styles etc)
   * -- should not be used for typography or color adjustments (font family, font weight, text color etc)
   */
   addtClassName: PropTypes.string,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    action: PropTypes.string,
    component: PropTypes.string,
    context: PropTypes.string,
    label: PropTypes.string
  }),
  /**
   * Disables the input
   */
  disabled: PropTypes.bool,
  /**
   * Error message to display
   */
  error: PropTypes.string,
  /**
   * Unique identifier for the checkbox group
   */
  id: PropTypes.string.isRequired,
  /**
   * Check if the field has already been submitted for validation
   */
  isSubmitted: PropTypes.bool,
  /**
   * Check if the field is valid
   */
  isValid: PropTypes.bool,
  /**
   * Sets the input to full width
   */
  fullWidth: PropTypes.bool,
  /**
   * Checkbox group label
   */
  label: PropTypes.string,
  /**
   * Name of the checkbox group (for form submission)
   */
  name: PropTypes.string,
  /**
   * Checkboxes to render
   */
   options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  /**
   * Mark the checkbox as required
   */
  required: PropTypes.bool,
  /**
   * Callback function to set the value of the checkbox group
   */
  setValue: PropTypes.func,
  /**
   * Design theme
   */
  theme: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  /**
   * Current value of the checkbox group
   */
   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

RadioGroup.defaultProps = {
  analytics: {
    action: '<Purpose of the interaction>',
    component: '<Parent component container identifier>',
    context: '<Additional information to add context to avoid ambiguity>',
    label: '<Text displayed within the button element>'
  },
  id: 'radio-group',
  theme: 'primary',
  value: undefined
}

RadioGroup.name = 'FormElement.radiogroup'
export default RadioGroup
