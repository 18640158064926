import React from 'react'
import PropTypes from 'prop-types'
import styles from './TextView.module.scss'
import * as Framework from 'components/framework'

/**
 * The Radio Component is used as...
 */
const TextView = React.forwardRef(({
  addtClassName,
  analytics,
  checked,
  disabled,
  id,
  label,
  name,
  theme,
  value,
  ...props
}, ref) => {

  const cmpTheme = {
    label: {
      color: 'text-color--black',
      font: 'font--gotham-book',
      size: 'text-size--regular'
    }
  }
  
  return (
    <label
      className={styles[theme] + (addtClassName ? ' ' + addtClassName : '')}
      id={`${id}-wrapper`}
    >
      {label && (
        <Framework.Text
          className={ styles.label}
          content={label}
          id={`${id}-label`}
          inline
          theme={cmpTheme.label}
        />
      )}
    </label>
  )
})

TextView.propTypes = {
  /**
   * Additional Class Name - passed from the parent Component
   * -- allows box model adjustments (margin, padding, position styles etc)
   * -- should not be used for typography or color adjustments (font family, font weight, text color etc)
   */
   addtClassName: PropTypes.string,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    action: PropTypes.string,
    component: PropTypes.string,
    context: PropTypes.string,
    label: PropTypes.string
  }),
  /**
   * Checked state of the radio button
   */
  checked: PropTypes.bool,
  /**
   * Disabled - disables the input
   */
  disabled: PropTypes.bool,
  /**
   * ID - unique identifier for the textfield
   */
  id: PropTypes.string,
  /**
   * Select label
   */
  label: PropTypes.string,
  /**
   * Name of the input
   */
  name: PropTypes.string,
  /**
   * Optional change handler
   */
  /**
   * Design theme
   */
  theme: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  /**
   * Value of the input
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

TextView.defaultProps = {
  analytics: {
    action: '<Purpose of the interaction>',
    component: '<Parent component container identifier>',
    context: '<Additional information to add context to avoid ambiguity>',
    label: '<Text displayed within the button element>'
  },
  theme: 'primary'
}

TextView.name = 'FormElement.checkbox'
export default TextView
