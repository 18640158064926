import { Page, withModel } from '@adobe/aem-react-editable-components'
import PageComponent from 'components/scaffolding/Page/Page'

// This component is the application entry point
class App extends Page {
  render () {
    // Wrapping all components in the root page in a wrapper page component,
    // so that it supports SPA routing
    return (
      <>
        <PageComponent key={this.props?.cqPath} {...this.props}/>
        {this.childPages}
      </>
    )
  }
}

export default withModel(App)
