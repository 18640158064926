import * as Framework from 'components/framework'

const SearchFormConfig = {
  service: undefined,
  form: {
    layout: 'custom',
    theme: 'custom',
    fields: [
      { // Text field
        type: 'text',
        params: {
          label: 'Search...',
          name: 'search',
          required: false,
          theme: 'tertiary'
        }
      },
    ],
    buttons: [
      { // Submit button
        type: 'submit',
        params: {
          label: (
            <Framework.Icon icon='search' />
          ),
          theme: {
            style: 'secondary',
            type: 'wrapper'
          }
        }
      }
    ]
  }
}

export default SearchFormConfig
