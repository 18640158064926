import { MapTo } from '@adobe/aem-react-editable-components'
import withAsyncImport from 'utils/withAsyncImport'

const Search = withAsyncImport(() =>
  import('./Search')
)

const SearchEditableConfig = {
  emptyLabel: 'Search',

  isEmpty: function (props) {
    return !props || !props.configured
  }
}

export default MapTo('aramark/components/content/search')(Search, SearchEditableConfig)
