import React from 'react'
import PropTypes from 'prop-types'
import styles from './NavigationMainUtility.module.scss'
import * as Framework from 'components/framework'

/**
 * The Navigation - Main - Utility Component is used to display utility nav content
 */
const NavigationMainUtility = ({
  addtOnClick,
  analytics,
  isActive,
  content
}) => {

  const cmpTheme = {
    link: {
      style: 'textMainNavLinkUtilityMobile',
      type: 'link'
    },
    secondaryLink: {
      style: 'textMainNavLinkUtilityMobile',
      type: 'link'
    }
  }

  const onMenuChange = (event) => {
    addtOnClick()
    const link = event.target.value
    // window.open(link, '_blank')
    window.location.assign(link);

  }

  const generateCountryOptions = () => {
    const options = []
    content?.linkCountries?.forEach((lc) => {
      options.push({
        disabled: true,
        label: lc?.header,
        value: ''
      })
      lc?.linkCountry?.forEach((country) => {
        options.push({
          disabled: false,
          label: country?.label,
          value: country?.link
        })
      })
    })
    return options
  }

  const countryOptions = generateCountryOptions()

  const generateShopOptions = () => {
    const options = content?.cards?.map((card) => ({
      label: card?.link?.label,
      value: card?.link?.href
    }))
    return options
  }

  const shopOptions = generateShopOptions()

  return (
    <div className={
      styles.utility +
      (isActive ? ' ' + styles.isActive : '')
    }>
      <ul className={styles.links}>
        {content?.links?.length > 0 && content?.links.map((link, index) => (
          <li key={index}>
            <Framework.Anchor
              addtOnClick={addtOnClick}
              analytics={{
                component: analytics?.component,
              }}
              endIcon={link?.endIcon}
              href={link?.href}
              label={link?.label}
              startIcon={link?.startIcon}
              target={link?.target}
              theme={cmpTheme?.link}
            />
          </li>
        ))}
        {content?.secondaryLinks?.length > 0 && content?.secondaryLinks.map((secondaryLink, index) => (
          <li key={index}>
            <Framework.Anchor
              addtOnClick={addtOnClick}
              analytics={{
                component: analytics?.component,
              }}
              endIcon={secondaryLink?.endIcon || ((secondaryLink?.target === '_blank') ? 'openInNewTabSmall' : null)}
              href={secondaryLink?.href}
              label={secondaryLink?.label}
              startIcon={secondaryLink?.startIcon}
              target={secondaryLink?.target}
              theme={cmpTheme?.secondaryLink}
            />
          </li>
        ))}
        <li className={styles.country}>
          <Framework.FormSelect
            analytics={{
              component: analytics?.component,
              context: 'Utility Country Select'
            }}
            id='countries-select'
            label={content?.labelCountries}
            onChange={onMenuChange}
            options={countryOptions}
            theme='tertiary'
          />
        </li>
        <li className={styles.shop}>
          <Framework.FormSelect
            analytics={{
              component: analytics?.component,
              context: 'Utility Shop Select'
            }}
            id='shop-select'
            label={content?.labelShopping}
            onChange={onMenuChange}
            options={shopOptions}
            theme='tertiary'
          />
        </li>
      </ul>
    </div>
  )
}

NavigationMainUtility.propTypes = {
  /**
   * Additional On Click function
   */
  addtOnClick: PropTypes.func,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    component: PropTypes.string,
    context: PropTypes.string
  }),
  /**
   * isActive - display the nav
   */
  isActive: PropTypes.bool,
  /**
   * Utility Nav content
   */
  content: PropTypes.object
}

export default NavigationMainUtility
