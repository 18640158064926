import React from 'react'
import PropTypes from 'prop-types'
import styles from './Navigation.module.scss'
import extractModelId from 'utils/extract-model-id'
import { useWindowSize } from 'hooks/useWindowSize'

/**
 * The Navigation Component is used to display main and utility
 * navigation in a cohesive format
 */
const Navigation = ({ 
  cqItems,
  ...props
}) => {

  const screenSize = useWindowSize()

  return (
    <header
      className={styles.navigation}
      data-component='Navigation'
      id={extractModelId(props?.cqPath)}
      title={props?.title}
    >
      {screenSize.width > 1160 ? (
        <>
          {React.cloneElement(props.children[1], { ...cqItems?.utilityNav })}
          {React.cloneElement(props.children[0], { ...cqItems?.mainNav })}
        </>
      ) : (
        <>
          {React.cloneElement(props.children[0], {
            ...cqItems?.mainNav,
            utilityNav: cqItems?.utilityNav
          })}
        </>
      )}
    </header>
  )
}

Navigation.propTypes = {
  /**
   * Main & Utility Navigation model payloads
   */
  cqItems: PropTypes.object
}

Navigation.defaultProps = {}

export default Navigation
