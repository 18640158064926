import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styles from './Carousel.module.scss'

import Button from '../Button'

import { useCarousel } from 'hooks/useCarousel'

/**
 * The Carousel Component is used as...
 */
const Carousel = ({
  addtClassName,
  autoplay,
  children,
  id,
  initialActiveItem,
  initialStartingPosition,
  itemsPerSlide,
  labelNext,
  labelPrev,
  theme,
  type,
  withLoop,
  withSlider,
  ...props
}) => {
  // Set initial active slide for the carousel slider
  const [activeSlide, setActiveSlide] = useState(initialActiveItem)
  // Prepare items object for useCarousel hook
  const [items] = useState(
    React.Children.map(children, (child, index) => {
      if (React.isValidElement(child)) {
        return {
          renderItem: React.cloneElement(child, {
            id: id ? `${id}-carousel-slide-${index}` : `carousel-slide-${index}`
          }),
        }
      }
    })
  )
  const {
    carouselFragment,
    getIsPaused,
    slideToItem,
    slideToPrevItem,
    slideToNextItem,
    togglePause,
    useListenToCustomEvent
  } = useCarousel({
    autoplay: autoplay || false,
    initialActiveItem: initialActiveItem,
    itemsPerSlide: itemsPerSlide,
    carouselSlideAxis:  theme === 'primary' ? 'x' : 'y',
    thumbsSlideAxis: theme === 'primary' ? 'x' : 'y',
    withLoop: withLoop,
    type: type,
    items: items
  })

  /*
   * To improve overall performance, useCarousel doesn't normally cause re-renders on
   * slide changes or other events, use this if you need to have access to the current
   * active slide or other similar values.
   * This causes a component re-render on every event, so use it only if necessary.
   * https://herodigital.atlassian.net/wiki/spaces/ARR/pages/2574155834/Implementation+Carousel
   */
  useListenToCustomEvent((data) => {
    // Listen to slide changes and update activeSlide
    if (data.eventName === "onSlideStartChange") {
      setActiveSlide(data.nextItem)
    }
  });

  return (
    <section
      className={
        styles[theme] +
        (addtClassName ? ' ' + addtClassName : '')
      }
      id={id ? id + '-carousel' : 'carousel'}
      {...props}
    >
      <div
        className={styles[`carousel-wrapper-${theme}`]}
        id={id ? id + '-carousel-wrapper' : 'carousel-wrapper'}
      >
        <Button
          className={styles.prev}
          content={labelPrev ?? theme === 'primary' ? '◀' : '▲'}
          id={id ? id + '-carousel-prev' : 'carousel-prev'}
          label={labelPrev ?? theme === 'primary' ? '◀' : '▲'}
          onClick={slideToPrevItem}
          theme={{
            style: {},
            type: 'wrapper'
          }}
          type='prev'
        />
        {carouselFragment}
        <Button
          className={styles.next}
          content={labelNext ?? theme === 'primary' ? '▶' : '▼'}
          id={id ? id + '-carousel-next' : 'carousel-next'}
          label={labelNext ?? theme === 'primary' ? '▶' : '▼'}
          onClick={slideToNextItem}
          theme={{
            style: {},
            type: 'wrapper'
          }}
          type='next'
        />
      </div>

      {/* Render an optional slider control */}
      <div className={styles[`thumbs-container-${theme}`]}>
        {withSlider && items.map((_item, index) => (
          <button
            key={index}
            className={
              styles[`thumb-${theme}`] +
              (activeSlide === index ? ' ' + styles['thumb-active'] : '')
            }
            onClick={() => slideToItem(index)}
          />
        ))}
      </div>

      {/* Render an optional pause button */}
      <div>
        {autoplay && (
          <Button
            className={styles.pause}
            content={getIsPaused() ? '▶' : '❚❚'}
            id={id ? id + '-carousel-pause' : 'carousel-pause'}
            theme={{
              style: {},
              type: 'wrapper'
            }}
            label={getIsPaused() ? '▶' : '❚❚'}
            onClick={togglePause}
            type='pause'
          />
        )}
      </div>
    </section>
  )
}

Carousel.propTypes = {
  /**
   * Additional Class Name - passed from the parent Component
   * -- Can be used to override default component styles by targeting a specific control's ID.
   * -- #carousel-wrapper, #carousel-pause, #carousel-prev, #carousel-next, #carousel-thumbs-container.
   * If a unique ID is provided to the component, it is added to the beginning of the control's ID
   * ex: '#hero-carousel-wrapper, #hero-carousel-pause.'
   */
  addtClassName: PropTypes.string,
  /**
   * If an autoplay value in milliseconds is provided, the carousel will automatically advance to the next slide
   */
  autoplay: PropTypes.number,
  /**
   * Unique ID for the Carousel, if proided, will be used to generate the ID's for the carousel's controls
   */
  id: PropTypes.string,
  /**
   * Initial active item
   */
  initialActiveItem: PropTypes.number,
  /**
   * Initial starting position
   */
  initialStartingPosition: PropTypes.oneOf(['start', 'center', 'end']),
  /**
   * Items per slide
   */
  itemsPerSlide: PropTypes.number,
  /**
   * Label for the next button
   */
  labelNext: PropTypes.any,
  /**
   * Label for the previous button
   */
  labelPrev: PropTypes.any,
  /**
   * Design theme
   */
  theme: PropTypes.oneOf(['primary', 'secondary']),
  /**
   * Set the carousel animation type
   */
  type: PropTypes.oneOf(['fade', 'spring']),
  /**
   * Optional looping of carousel items
   */
  withLoop: PropTypes.bool,
  /**
   * Optional display of slide controls
   */
  withSlider: PropTypes.bool,
}

Carousel.defaultProps = {
  initialActiveItem: 0,
  itemsPerSlide: 1,
  theme: 'primary',
  type: 'spring',
  withLoop: true,
  withSlider: false
}

export default Carousel
