import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './NavigationUtility.module.scss'
import * as Framework from 'components/framework'
import extractModelId from 'utils/extract-model-id'
import useKeyPress from 'hooks/useKeyPress'
import Card from './Card'
import List from './List'

/**
 * The Navigation - Utility Component is used to render a global utility navigation on each page
 */
const NavigationUtility = ({
  labelCountries,
  labelShopping,
  links,
  linkCountries,
  cards,
  secondaryLinks,
  ...props
}) => {
  const utilityNav = useRef()

  const cmpAnalytics = {
    component: props?.cqType
  }

  const cmpTheme = {
    menus: {
      color: 'text-color--light-gray',
      font: 'font--gotham-bold',
      size: 'text-size--small'
    },
    link: {
      style: 'utility',
      type: 'button'
    },
    secondaryLink: {
      style: 'utilityLink',
      type: 'link'
    },
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [activeMenu, setActiveMenu] = useState(null)

  const shouldMenuBeOpen = (menu) => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true)
      return
    }
    if ((activeMenu === menu) && isMenuOpen) {
      setIsMenuOpen(false)
      return
    }
  }

  useKeyPress('Escape', () => {
    setIsMenuOpen(false)
  })

  useEffect(() => {
    const handleClickOutsideNav = event => {
      if (isMenuOpen && utilityNav.current && event.target !== window
        && !utilityNav.current.contains(event.target)) {
          setIsMenuOpen(false)
      }
    }
    document.addEventListener("click", handleClickOutsideNav)

    return () => {
      document.removeEventListener("click", handleClickOutsideNav)
    }
  }, [isMenuOpen])

  return (
    <nav
      className={styles.utility}
      data-component='NavigationUtility'
      id={extractModelId(props?.cqPath)}
      title={props?.title}
      ref={utilityNav}
    >
      <ul className={isMenuOpen ? styles.bar + ' ' + styles.open : styles.bar}>
        {labelCountries && <li className={activeMenu === 'country' ? styles.active + ' ' + styles.bar : styles.bar}>
          <Framework.Button
            addtClassName={styles.bar__button}
            analytics={{
              action: 'Open/Close Country Menu',
              component: cmpAnalytics?.component
            }}
            content={
              <>
                <Framework.Text
                  content={labelCountries}
                  inline={true}
                  theme={cmpTheme?.menus}
                />
                {activeMenu === 'country' && isMenuOpen === true ? <Framework.Icon icon='chevronUpSmall' /> : <Framework.Icon icon='chevronDownSmall' />}
              </>
            }
            label={labelCountries}
            onClick={() => {
              setActiveMenu('country')
              shouldMenuBeOpen('country')
            }}
            theme={{
              style: {},
              type: 'wrapper'
            }}
          />
          {linkCountries?.length > 0 && <div className={styles.menu}>
            <div className={styles.menu__inner}>
              <ul className={styles.menu__items}>
                {linkCountries?.map((list, index) => (
                  <li key={index}>
                    <List
                      analytics={{
                        component: cmpAnalytics?.component
                      }}
                      header={list.header}
                      linkCountry={list.linkCountry}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>}
        </li>}
        {labelShopping && <li className={activeMenu === 'shop' ? styles.active + ' ' + styles.bar : styles.bar}>
          <Framework.Button
            addtClassName={styles.bar__button}
            analytics={{
              action: 'Open/Close Shop Menu',
              component: cmpAnalytics?.component
            }}
            content={
              <>
                <Framework.Text
                  content={labelShopping}
                  inline={true}
                  theme={cmpTheme?.menus}
                />
                {activeMenu === 'shop' && isMenuOpen === true ? <Framework.Icon icon='chevronUpSmall' /> : <Framework.Icon icon='chevronDownSmall' />}
              </>
            }
            label={labelShopping}
            onClick={() => {
              setActiveMenu('shop')
              shouldMenuBeOpen('shop')
            }}
            theme={{
              style: {},
              type: 'wrapper'
            }}
          />
          {cards?.length > 0 && <div className={styles.menu}>
            <div className={styles.menu__inner}>
              <ul className={styles.menu__items}>
              {cards?.map((card, index) => (
                <li key={index}>
                  <Card
                    analytics={{
                      component: cmpAnalytics?.component
                    }}
                    image={card.image}
                    link={card.link}
                    title={card.title}
                  />
                </li>
              ))}
              </ul>
            </div>
          </div>}
        </li>}
        {secondaryLinks?.length > 0 && <li className={styles.bar}>
          <ul className={styles.secondaryLinks}>
            {secondaryLinks?.map((secondaryLink, index) => (
              <li key={index}>
                <Framework.Anchor
                  analytics={{
                    component: cmpAnalytics?.component
                  }}
                  endIcon={secondaryLink?.endIcon || ((secondaryLink?.target === '_blank') ? 'openInNewTabSmall' : null)}
                  href={secondaryLink?.href}
                  label={secondaryLink?.label}
                  startIcon={secondaryLink?.startIcon}
                  target={secondaryLink?.target}
                  theme={cmpTheme?.secondaryLink}
                />
              </li>
            ))}
          </ul>
        </li>}
        {links?.length > 0 && <li className={styles.bar}>
          <ul className={styles.links}>
            {links?.map((link, index) => (
              <li key={index}>
                <Framework.Anchor
                  analytics={{
                    component: cmpAnalytics?.component
                  }}
                  endIcon={link?.endIcon}
                  href={link?.href}
                  label={link?.label}
                  startIcon={link?.startIcon}
                  target={link?.target}
                  theme={cmpTheme?.link}
                />
              </li>
            ))}
          </ul>
        </li>}
      </ul>
    </nav>
  )
}

NavigationUtility.propTypes = {
  /**
   * Cards
   */
  cards: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.shape({
      altText: PropTypes.string,
      defaultSrc: PropTypes.string,
      mobileSrc: PropTypes.string
    }),
    link: PropTypes.shape({
      endIcon: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      startIcon: PropTypes.string,
      target: PropTypes.string
    }),
    title: PropTypes.string
  })),
  /**
   * Country Dropdown Label
   */
  labelCountries: PropTypes.string,
  /**
   * Shop Dropdown Label
   */
  labelShopping: PropTypes.string,
  /**
   * Link (Call To Action)
   */
  link: PropTypes.shape({
    endIcon: PropTypes.string,
    href: PropTypes.string,
    label: PropTypes.string,
    startIcon: PropTypes.string,
    target: PropTypes.string
  }),
  /**
   * Link Countries
   */
   linkCountries: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      linkCountry: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          link: PropTypes.string
        })
      )
    })
  ),
  /**
   * Links
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      icon: PropTypes.string,
      label: PropTypes.string,
      target: PropTypes.string,
    })
  ),
  /**
   * Secondary Links
   */
  secondaryLinks: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      icon: PropTypes.string,
      label: PropTypes.string,
      target: PropTypes.string,
    })
  )
}
NavigationUtility.defaultProps = {}

export default NavigationUtility
